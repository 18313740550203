import { Bundle } from 'apiHelpers/quote/quoteResponse';
import { AddOnPrices } from 'businessLogic/addOns';
import { AddOn } from './businessConstants';

export const getAddOnPricesForSelectedBundle = (
  bundle: Bundle,
  addOn: AddOn
): AddOnPrices => {
  const addOnCover = bundle.covers.find((cover) => cover.cover.name === addOn);

  if (!addOnCover) {
    throw new Error('Insufficient data available to calculate price');
  }

  return {
    coverCode: addOn,
    annualPricePerPet: addOnCover?.annuallyBundleCoverPrice.petPrices,
    monthlyPricePerPet: addOnCover?.monthlyBundleCoverPrice.petPrices,
  };
};

const sumAddOnPricesPerPet = (addOnPrices: number[][]): number[] =>
  addOnPrices.reduce((addOnPriceTotalPerPet, addOnPricePerPet) =>
    addOnPriceTotalPerPet.map((total, i) => total + addOnPricePerPet[i])
  );

export const getAddOnsTotalPricePerPet = (
  addOnPricesPerPet: AddOnPrices[]
): { annualPricePerPet: number[]; monthlyPricePerPet: number[] } => {
  if (addOnPricesPerPet.length === 0) {
    return {
      annualPricePerPet: [],
      monthlyPricePerPet: [],
    };
  }

  const annualPricePerPet = sumAddOnPricesPerPet(
    addOnPricesPerPet.map((price) => price.annualPricePerPet)
  );

  const monthlyPricePerPet = sumAddOnPricesPerPet(
    addOnPricesPerPet.map((price) => price.monthlyPricePerPet)
  );

  return {
    annualPricePerPet,
    monthlyPricePerPet,
  };
};

import PageWidthContainer from '@rsa-digital/evo-shared-components/components/PageWidthContainer';
import ProgressBar from '@rsa-digital/evo-shared-components/components/ProgressBar';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const StyledProgressBar = styled(ProgressBar)`
  margin-bottom: ${spacing(4)};

  ${mediaQuery.tabletPortrait`
    margin-top: ${spacing(-1.75)};
  `}

  ${mediaQuery.tabletLandscape`
    margin-bottom: ${spacing(6)};
  `}
`;

export const StyledBackground = styled(PageWidthContainer)`
  display: hidden;
  ${mediaQuery.tabletPortrait`
    display: block;
    height: ${spacing(1.75)};
  `}
`;

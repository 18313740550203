import { formatInPounds } from 'helpers/poundsHelpers';
import { CurrentQuote } from 'helpers/useCurrentQuote';
import { combinePetNames } from './combinePetNames';
import { CsPlaceholders } from './replaceCsPlaceholders';

const addOnPlaceholders: CsPlaceholders<{ quote: CurrentQuote; price: number }> = {
  petNames: {
    getSubstitutionText: ({ quote }) => combinePetNames(quote, true),
    isPii: true,
  },
  addOnCost: {
    getSubstitutionText: ({ price }) => formatInPounds(price),
    isPii: false,
  },
};

export default addOnPlaceholders;

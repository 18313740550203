import React, { Dispatch, PropsWithChildren, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RECAPTCHA_SKIP_KEY_SESSION_KEY, storeData } from 'helpers/sessionStorageHelpers';
import {
  UPDATE_DISABLE_DATE_CHECKS,
  UpdateDisableDateChecksAction,
} from 'state/timeTravel/actions';
import { getQueryParam } from '../../helpers/getQueryParam';

const processRecaptchaSkipKey = (location: Location): void => {
  const recaptchaSkipKey = getQueryParam(location, 'recaptchaSkipKey');

  if (recaptchaSkipKey) {
    storeData(RECAPTCHA_SKIP_KEY_SESSION_KEY, recaptchaSkipKey);
  }
};

/**
 * Context: some backend system environments do 'time travelling' where the system time is changed in order to allow time-dependent
 * testing. In the front end we support this by disabling any FE date validation/logic, and passing through the appropriate flag to the
 * relevant endpoints (apply quote, requote, retrieve quote and current quote).
 */

/**
 * Listens for the disableDateChecks query param in order to turn 'time travel' on/off.
 */
const processDisableDateChecksQueryParam = (
  location: Location,
  dispatch: Dispatch<UpdateDisableDateChecksAction>
): void => {
  const disableDateChecks = getQueryParam(location, 'disableDateChecks');

  /* istanbul ignore next */
  if (disableDateChecks === 'true') {
    dispatch({ type: UPDATE_DISABLE_DATE_CHECKS, disableDateChecks: true });
  } else if (disableDateChecks === 'false') {
    dispatch({ type: UPDATE_DISABLE_DATE_CHECKS, disableDateChecks: false });
  }
};

const SessionQueryParamWrapper: React.FC<PropsWithChildren<{
  location: Location;
}>> = ({ location, children }) => {
  const dispatch = useDispatch<Dispatch<UpdateDisableDateChecksAction>>();

  useEffect(() => {
    processRecaptchaSkipKey(location);
    processDisableDateChecksQueryParam(location, dispatch);
  }, [location, dispatch]);

  return <>{children}</>;
};

export default SessionQueryParamWrapper;

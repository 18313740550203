import { DateValue } from '@rsa-digital/evo-shared-components/components/Form/DateInput';
import { localDateToUtcDate } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'state/createStore';
import { initialDateValue } from './shared/dateValue';

export const UPDATE_POLICY_DETAILS = 'UPDATE_POLICY_DETAILS';

export type JointPolicyholderDetails = {
  includeJointPolicyholder: boolean;
  jointPolicyholderFirstName: string;
  jointPolicyholderLastName: string;
  jointPolicyholderDob: DateValue;
  jointPolicyholderTitle: string;
};

export type PolicyDetails = {
  coverStartDate: string;
  keepingYouInformed: boolean | undefined;
  numberOfPetsInHousehold: number | undefined;
} & JointPolicyholderDetails;

export const initialJointPolicyholderDetails = {
  includeJointPolicyholder: false,
  jointPolicyholderDetails: undefined,
  jointPolicyholderFirstName: '',
  jointPolicyholderLastName: '',
  jointPolicyholderDob: initialDateValue,
  jointPolicyholderTitle: '',
};

export const initialPolicyDetails: PolicyDetails = {
  coverStartDate: localDateToUtcDate(new Date()).toISOString(),
  keepingYouInformed: undefined,
  numberOfPetsInHousehold: undefined,
  ...initialJointPolicyholderDetails,
};

export type UpdatePolicyDetailsAction = {
  type: typeof UPDATE_POLICY_DETAILS;
  update: PolicyDetails;
};

export const usePolicyDetails = (): [
  PolicyDetails,
  (update: Partial<PolicyDetails>) => void
] => {
  const policyDetails = useSelector((state: RootState) => state.policyDetails);
  const dispatch = useDispatch();

  const updatePolicyDetails = useCallback(
    (update: Partial<PolicyDetails>): void => {
      dispatch({ type: UPDATE_POLICY_DETAILS, update });
    },
    [dispatch]
  );

  return [policyDetails, updatePolicyDetails];
};

import SimpleHeader from '@rsa-digital/evo-shared-components/components/Header/SimpleHeader';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled, { css } from 'styled-components';

type OptionalShadowHeaderProps = {
  displayShadow: boolean;
};

export const OptionalShadowHeader = styled(SimpleHeader)<OptionalShadowHeaderProps>`
  ${(props) =>
    props.displayShadow
      ? css`
          margin-bottom: ${spacing(6)};
        `
      : css`
          &&& {
            box-shadow: none;
          }
        `}
`;

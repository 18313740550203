import { Bundle, BundleContainer, Quote } from 'apiHelpers/quote/quoteResponse';
import { addOnMapping, AddOnType } from 'businessLogic/addOns';
import flatMap from 'lodash/flatMap';
import {
  CORE_COVER,
  CoverLevel,
  VetBillsAccidentsAndIllness,
} from 'helpers/businessConstants';
import {
  AccidentAndIllnessCoverDuration,
  AddOnsSelected,
  BasicCoverFeeLimit,
  ClassicCoverFeeLimit,
  CoverType,
  initialQuoteOptions,
  PremierCoverFeeLimit,
  QuoteOptions,
} from 'state/formData/quoteOptions';
import { QuoteCover, RequoteParams } from './quoteRequest';
import { BundleCover } from './quoteResponse';

export const hasUserSelectedCover = (quote: Quote): boolean =>
  !!quote.petInfos[0]?.userSelectedCover;

const convertAddOnSelectedFalsesToNulls = (
  addOnsSelected: AddOnsSelected
): AddOnsSelected =>
  Object.fromEntries(
    Object.entries(addOnsSelected).map(([_, selected]) => [_, selected ? true : null])
  ) as AddOnsSelected;

export const getQuoteOptionsFromCoverLevel = (
  coverLevel: CoverLevel | undefined,
  addOnsSelected?: AddOnsSelected
): QuoteOptions => {
  const defaultAddOnsForQuote = addOnsSelected
    ? convertAddOnSelectedFalsesToNulls(addOnsSelected)
    : undefined;

  const defaultQuoteOptions: QuoteOptions = {
    ...initialQuoteOptions,
    ...(defaultAddOnsForQuote && { addOnsSelected: defaultAddOnsForQuote }),
  };

  const basicQuoteOptions: QuoteOptions = {
    ...defaultQuoteOptions,
    accidentAndIllnessCoverDuration: AccidentAndIllnessCoverDuration.Short_Term,
  };

  const classicQuoteOptions: QuoteOptions = {
    ...defaultQuoteOptions,
    accidentAndIllnessCoverDuration: AccidentAndIllnessCoverDuration.Until_Limit,
  };

  const premierQuoteOptions: QuoteOptions = {
    ...defaultQuoteOptions,
    accidentAndIllnessCoverDuration: AccidentAndIllnessCoverDuration.Ongoing,
  };

  switch (coverLevel) {
    case CoverLevel.BASIC_1500:
      return {
        ...basicQuoteOptions,
        basicCoverFeeLimit: BasicCoverFeeLimit.Limit_1500,
      };
    case CoverLevel.BASIC_3000:
      return {
        ...basicQuoteOptions,
        basicCoverFeeLimit: BasicCoverFeeLimit.Limit_3000,
      };

    case CoverLevel.CLASSIC_4000:
      return {
        ...classicQuoteOptions,
        classicCoverFeeLimit: ClassicCoverFeeLimit.Limit_4000,
      };
    case CoverLevel.CLASSIC_8000:
      return {
        ...classicQuoteOptions,
        classicCoverFeeLimit: ClassicCoverFeeLimit.Limit_8000,
      };

    case CoverLevel.PREMIER_1000:
      return {
        ...premierQuoteOptions,
        premierCoverFeeLimit: PremierCoverFeeLimit.Limit_1000,
      };
    case CoverLevel.PREMIER_2000:
      return {
        ...premierQuoteOptions,
        premierCoverFeeLimit: PremierCoverFeeLimit.Limit_2000,
      };
    case CoverLevel.PREMIER_4000:
      return {
        ...premierQuoteOptions,
        premierCoverFeeLimit: PremierCoverFeeLimit.Limit_4000,
      };
    case CoverLevel.PREMIER_6000:
      return {
        ...premierQuoteOptions,
        premierCoverFeeLimit: PremierCoverFeeLimit.Limit_6000,
      };

    default:
      return { ...defaultQuoteOptions };
  }
};

/**
 * Gets the bundle on the quote associated with the cover level supplied.
 * Note that this assumes there is only one version of each bundle in the quote.
 *
 * @param coverLevel The cover level to find the bundle for
 * @param quote The quote, as returned from the API
 * @returns The bundle associated with the supplied cover level
 */
export const getBundleFromQuote = (
  coverLevel: CoverLevel | undefined,
  quote: Quote
): Bundle | undefined => {
  if (coverLevel === undefined) {
    return undefined;
  }

  const allBundles = flatMap(
    quote.premium.bundleContainers,
    (container: BundleContainer) => container.bundles
  );

  return allBundles.find((bundle: Bundle) => bundle.coverLevelRequired === coverLevel);
};

export const getCoverLevelFromQuoteOptions = (
  quoteOptions: QuoteOptions
): CoverLevel | undefined => {
  const {
    coverType,
    accidentAndIllnessCoverDuration,
    basicCoverFeeLimit,
    classicCoverFeeLimit,
    premierCoverFeeLimit,
  } = quoteOptions;

  if (coverType === CoverType.Accidents_Only) {
    return CoverLevel.ACCIDENT_ONLY;
  }

  if (
    accidentAndIllnessCoverDuration === AccidentAndIllnessCoverDuration.Short_Term &&
    basicCoverFeeLimit
  ) {
    switch (basicCoverFeeLimit) {
      case BasicCoverFeeLimit.Limit_1500:
        return CoverLevel.BASIC_1500;
      case BasicCoverFeeLimit.Limit_3000:
        return CoverLevel.BASIC_3000;
      default:
        return undefined;
    }
  }

  if (
    accidentAndIllnessCoverDuration === AccidentAndIllnessCoverDuration.Until_Limit &&
    classicCoverFeeLimit
  ) {
    switch (classicCoverFeeLimit) {
      case ClassicCoverFeeLimit.Limit_4000:
        return CoverLevel.CLASSIC_4000;
      case ClassicCoverFeeLimit.Limit_8000:
        return CoverLevel.CLASSIC_8000;
      default:
        return undefined;
    }
  }

  if (
    accidentAndIllnessCoverDuration === AccidentAndIllnessCoverDuration.Ongoing &&
    premierCoverFeeLimit
  ) {
    switch (premierCoverFeeLimit) {
      case PremierCoverFeeLimit.Limit_1000:
        return CoverLevel.PREMIER_1000;
      case PremierCoverFeeLimit.Limit_2000:
        return CoverLevel.PREMIER_2000;
      case PremierCoverFeeLimit.Limit_4000:
        return CoverLevel.PREMIER_4000;
      case PremierCoverFeeLimit.Limit_6000:
        return CoverLevel.PREMIER_6000;
      default:
        return undefined;
    }
  }

  return undefined;
};

export const getCoverLevelFromQuote = (quote: Quote): CoverLevel => {
  // TODO: EP-255: Remove this function and inline quote.petInfos[0].coverLevelRequired where used
  return quote.petInfos[0].coverLevelRequired;
};

export const getAddOnsSelectedFromQuote = (quote: Quote): AddOnsSelected => {
  const coverLevel = getCoverLevelFromQuote(quote);
  const selectedBundle = getBundleFromQuote(coverLevel, quote);

  const addOnQuoteCovers = Object.entries(addOnMapping).map(
    ([addOnType, addOnCoverCode]) => {
      const selectedBundleAddOnCover = selectedBundle?.covers.find(
        (cover) => cover.cover.name === addOnCoverCode
      );

      const coverIsAddOnForCoverLevel =
        selectedBundleAddOnCover && !selectedBundleAddOnCover.coverIncludedIndicator;

      return [
        addOnType,
        // We check that selectedBundleAddOnCover is defined as part of `coverIsAddOnForCoverLevel`
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        coverIsAddOnForCoverLevel ? selectedBundleAddOnCover!.selected : null,
      ];
    }
  );

  return Object.fromEntries(addOnQuoteCovers);
};

export const getQuoteOptionsFromQuote = (quote: Quote): QuoteOptions => {
  if (!hasUserSelectedCover(quote)) {
    return initialQuoteOptions;
  }

  const addOnsSelected: AddOnsSelected = getAddOnsSelectedFromQuote(quote);
  const coverLevel = quote.petInfos[0].coverLevelRequired;
  return getQuoteOptionsFromCoverLevel(coverLevel, addOnsSelected);
};

/**
 * @param quote The quote being updated
 * @param quote The quote options for the current quote
 * @param coverLevel The cover level selected
 * @param coverStartDate (Optional) the start date for the cover, if different to the quote cover start date
 * @returns Array of covers to send for requoting
 */
const getQuoteCoversForCoverLevel = (
  quote: Quote,
  quoteOptions: QuoteOptions,
  coverLevel: CoverLevel | undefined,
  coverStartDate?: string
): QuoteCover[] => {
  const selectedBundle = getBundleFromQuote(coverLevel, quote);

  /* istanbul ignore if */
  if (!selectedBundle) {
    return [];
  }

  const getCoverIncludedIndicator = (bundleCover: BundleCover): boolean => {
    const addOn = Object.entries(addOnMapping).find(
      ([, coverName]) => bundleCover.cover.name === coverName
    );
    if (addOn) {
      const addOnSelected = quoteOptions.addOnsSelected[addOn[0] as AddOnType];

      if (addOnSelected !== null) {
        return addOnSelected;
      }
    }
    return (
      bundleCover.coverCode === CORE_COVER ||
      bundleCover.coverIncludedIndicator ||
      bundleCover.selected ||
      quote.covers.find((quoteCover) => bundleCover.coverCode === quoteCover.coverSection)
        ?.coreCoverIndicator ||
      false
    );
  };

  return selectedBundle.covers.map(
    (bundleCover): QuoteCover => ({
      coreCoverIndicator: bundleCover.coverCode === CORE_COVER,
      coverIncludedIndicator: getCoverIncludedIndicator(bundleCover),
      coverPreselected: false,
      coverSection: bundleCover.coverCode,
      // Cover start dates need to be supplied as an ISO string (i.e. with time component) so we add
      // that here manually if using the start date on the quote. We could also use the date on the
      // previous quoteCover (it should match), but doing it this way ensures it's definitely correct
      // and assumes less of the API! (TODO:EP-199)
      coverStartDate: coverStartDate ?? `${quote.policyInfo.coverStartDate}T00:00:00`,
    })
  );
};

const mapVetBillsParameters = (
  quoteOptions: QuoteOptions
): {
  vetBillsAccidentsAndIllness: VetBillsAccidentsAndIllness | undefined;
} => {
  if (
    !quoteOptions.accidentAndIllnessCoverDuration ||
    quoteOptions.accidentAndIllnessCoverDuration ===
      AccidentAndIllnessCoverDuration.Short_Term
  ) {
    return {
      vetBillsAccidentsAndIllness: VetBillsAccidentsAndIllness.SHORT_TERM,
    };
  }
  return {
    vetBillsAccidentsAndIllness: VetBillsAccidentsAndIllness.ONGOING,
  };
};

export const isQuoteOptionSelectionValid = (quoteOptions: QuoteOptions): boolean =>
  getCoverLevelFromQuoteOptions(quoteOptions) !== undefined;

/**
 * @param quote The quote being updated
 * @param quoteOptions The options the user has selected
 * @param coverStartDate (Optional) the start date for the cover, if different to the quote cover start date*
 * @returns Additional parameters required for the requote request
 *
 * *The start date on the cover objects needs to match the policy start date; the backend does
 * not handle this sensibly by default. In the backend/API sometimes start dates for individual
 * covers are used instead of the actual policy start date, so it's extra important these are in
 * sync!
 */
export const generateRequoteParameters = (
  quote: Quote,
  quoteOptions: QuoteOptions,
  coverStartDate?: string
): RequoteParams => {
  // TODO EP-255: If API is adapted to accept no covers selected this can be removed
  const selectedCoverLevel = getCoverLevelFromQuoteOptions(quoteOptions);
  const requestCoverLevel =
    selectedCoverLevel === undefined ? CoverLevel.BASIC_1500 : selectedCoverLevel;
  return {
    productId: quote.productId,
    coverLevelRequired: requestCoverLevel,
    covers: getQuoteCoversForCoverLevel(
      quote,
      quoteOptions,
      requestCoverLevel,
      coverStartDate
    ),
    isUserSelectedCover: isQuoteOptionSelectionValid(quoteOptions),
    ...mapVetBillsParameters(quoteOptions),
  };
};

/**
 * Gets selected covers that are included in the selected bundle for the quote, excluding the core cover.
 *
 * @param quote
 * @returns array of cover codes included in the quote
 */
export const getSelectedBundleCovers = (quote: Quote): string[] => {
  const coverLevel = getCoverLevelFromQuote(quote);
  const bundle = getBundleFromQuote(coverLevel, quote);

  if (!bundle) {
    return [];
  }

  return flatMap(quote.parentCover, (parentCoverCode: string) =>
    bundle.covers
      .filter(
        (cover) =>
          parentCoverCode !== CORE_COVER &&
          (cover.coverCode === parentCoverCode || cover.cover.name === parentCoverCode) &&
          cover.selected
      )
      .map((cover) => cover.coverCode)
  );
};

import { RESET_STATE, ResetStateAction } from 'state/actions';
import { mergeFormData } from './mergeFormData';
import { UPDATE_QUOTE_IN_PROGRESS, UpdateQuoteInProgressAction } from './quoteInProgress';
import {
  initialReferralCodes,
  ReferralCodes,
  UPDATE_REFERRAL_CODES,
  UpdateReferralCodesAction,
} from './referralCodes';

export const referralCodesReducer = (
  referralCodes: ReferralCodes = initialReferralCodes,
  action: UpdateReferralCodesAction | UpdateQuoteInProgressAction | ResetStateAction
): ReferralCodes => {
  switch (action.type) {
    case UPDATE_REFERRAL_CODES:
      return {
        ...referralCodes,
        ...action.update,
      };
    case UPDATE_QUOTE_IN_PROGRESS:
      if (action.quote.affinity || action.quote.subAffinity) {
        return mergeFormData(
          referralCodes,
          {
            affinity: action.quote.affinity ?? null,
            subAffinity: action.quote.subAffinity ?? null,
          },
          initialReferralCodes
        );
      }
      return referralCodes;
    case RESET_STATE:
      return initialReferralCodes;
    default:
      return referralCodes;
  }
};

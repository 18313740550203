import { TextLink } from '@rsa-digital/evo-shared-components/commonTypes/links';
import { ContactSectionType } from '@rsa-digital/evo-shared-components/components/Footer/ContactSection';
import { LargeLinkSectionType } from '@rsa-digital/evo-shared-components/components/Footer/FooterLinks';
import { graphql, useStaticQuery } from 'gatsby';
import { CsIcon } from 'types/contentStack';
import { unwrapSingleton } from './csTypeProcessors';

type FooterData = {
  footerCompliance: {
    compliance: string;
  };
  linkSection: LargeLinkSectionType;
  contactSection: ContactSectionType;
};

type FooterCsData = {
  csPetFooter: {
    footer: {
      useful_links: {
        heading: string;
        links: {
          text: string;
          url: string;
          open_in_new_tab: boolean;
        }[];
      };
      footer_compliance: {
        compliance: string;
      };
      contact_section: {
        title: string;
        content: string;
        icon: [CsIcon];
      };
    };
  };
};

const query = graphql`
  query {
    csPetFooter {
      footer {
        useful_links {
          heading
          links {
            text
            url
            open_in_new_tab
          }
        }
        footer_compliance {
          compliance
        }
        contact_section {
          title
          content
          icon {
            icon_code
          }
        }
      }
    }
  }
`;

export const useFooterData = (): FooterData => {
  const {
    footer: {
      footer_compliance,
      useful_links: { heading, links },
      contact_section: { title, content, icon },
    },
  } = useStaticQuery<FooterCsData>(query).csPetFooter;

  const unwrappedIcon = unwrapSingleton(icon)?.icon_code;

  return {
    footerCompliance: footer_compliance,
    linkSection: {
      title: heading,
      links: links.map(
        (link): TextLink => ({
          url: link.url,
          text: link.text,
          openInNewTab: link.open_in_new_tab,
        })
      ),
    },
    contactSection: {
      title,
      content,
      icon: unwrappedIcon,
    },
  };
};

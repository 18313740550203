import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const Container = styled(Grid)`
  margin-top: ${spacing(7)};
`;

export const DesktopHeadingItem = styled(GridItem)`
  display: none;

  ${mediaQuery.tabletLandscape`
    display: flex;
    flex-direction: column;
    justify-content: center;
  `}
`;

export const MobileHeadingItem = styled(GridItem)`
  ${mediaQuery.tabletLandscape`
    display: none;
  `}
`;

export const Heading = styled.h1`
  ${fonts.headingLarge}
  margin: ${spacing(3)} 0 0;
`;

export const Subhead = styled.p`
  text-align: left;
  ${fonts.paragraphLarge};
  margin-right: auto;
  margin-top: ${spacing(3)};

  ${mediaQuery.tabletPortrait`
    margin-top: ${spacing(2)};
  `}
`;

export const MobileSubhead = styled(RichText)`
  text-align: left;
  ${fonts.paragraphLarge};
  margin-top: ${spacing(3)};

  ${mediaQuery.tabletPortrait`
    display: none;
  `}
`;

export const StyledSubheading = styled(RichText)`
  display: none;

  ${mediaQuery.tabletPortrait`
    display: block;
    margin-bottom: ${spacing(3)};
    text-align: left;
  `}
`;

export const StyledMobileSubheading = styled(RichText)`
  text-align: center;
  margin-top: ${spacing(4)};
  margin-bottom: ${spacing(1)};
  ${mediaQuery.tabletPortrait`
    display: none;
  `}
`;

import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'state/createStore';

export const UPDATE_REFERRAL_CODES = 'UPDATE_REFERRAL_CODES';

export type ReferralCodes = {
  affinity: string | null;
  subAffinity: string | null;
};

export const initialReferralCodes: ReferralCodes = {
  affinity: null,
  subAffinity: null,
};

export type UpdateReferralCodesAction = {
  type: typeof UPDATE_REFERRAL_CODES;
  update: ReferralCodes;
};

export const useReferralCodes = (): [ReferralCodes, (update: ReferralCodes) => void] => {
  const referralCodes = useSelector((state: RootState) => state.referralCodes);
  const dispatch = useDispatch();

  const updateReferralCodes = useCallback(
    (update: ReferralCodes): void => {
      dispatch({ type: UPDATE_REFERRAL_CODES, update });
    },
    [dispatch]
  );

  return [referralCodes, updateReferralCodes];
};

import { graphql, useStaticQuery } from 'gatsby';
import { useCallback } from 'react';

type SiteWideSeo = {
  csSiteWideSeo: { title: string; favicon: { url: string } };
};

type PetGlobalConfig = {
  csPetGlobalConfig: { meta_title: string };
};

const metaQuery = graphql`
  query {
    csSiteWideSeo {
      title
      favicon {
        url
      }
    }
    csPetGlobalConfig {
      meta_title
    }
  }
`;

export const useMetaTitle = (): ((metaTitle: string) => string) => {
  const { csPetGlobalConfig, csSiteWideSeo } = useStaticQuery<
    SiteWideSeo & PetGlobalConfig
  >(metaQuery);
  return useCallback(
    (metaTitle) => `${metaTitle} ${csPetGlobalConfig.meta_title} ${csSiteWideSeo.title}`,
    [csPetGlobalConfig.meta_title, csSiteWideSeo.title]
  );
};

import { trimEnd } from 'lodash';
import { CurrentQuote } from 'helpers/useCurrentQuote';

export const combinePetNames = (quote: CurrentQuote, useOr?: boolean): string => {
  const joiningWord = useOr ? 'or' : 'and';

  const petNames = quote.petInfos?.map((pet) => trimEnd(pet.petName)) ?? [''];
  if (petNames.length === 1) {
    return petNames[0];
  }
  return `${petNames.slice(0, petNames.length - 1).join(', ')} ${joiningWord} ${
    petNames[petNames.length - 1]
  }`;
};

import { nations_UK } from 'helpers/referenceDataConstants';
import { RequoteRequest } from './quoteRequest';

// Due to aggregators having the quote return in an undesirable format we
// need to ensure the request is sent how the backend requires it.
export const cleanRequoteRequest = (request: RequoteRequest): RequoteRequest => {
  request.customerInfo.address.country =
    request.customerInfo.address.country || nations_UK;
  request.customerInfo.address.postcode = request.customerInfo.address.postcode?.replace(
    /\s+/g,
    ' '
  );

  return request;
};

import {
  InnerSection,
  SectionButton,
} from '@rsa-digital/evo-shared-components/components/Accordion/InnerAccordionSection/styles';
import SecondaryAccordion from '@rsa-digital/evo-shared-components/components/Accordion/SecondaryAccordion';
import { Grid } from '@rsa-digital/evo-shared-components/components/Grid';
import Table, { TableCell } from '@rsa-digital/evo-shared-components/components/Table';
import { StyledTableHeaderCell } from '@rsa-digital/evo-shared-components/components/Table/TableCell/styles';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled, { css } from 'styled-components';

export const TableWithOptionalSharpCorners = styled(Table)<{
  sharpTopLeftCorner?: boolean;
  sharpTopRightCorner?: boolean;
  sharpBottomLeftCorner?: boolean;
  sharpBottomRightCorner?: boolean;
}>`
  // Setting the background to white (rather than letting it just be default as white) stops a CSS quirk that draws the cell border double thickness on the adjacent tables
  background-color: ${colors.neutral06};
  border-radius: ${spacing(1)}
    ${({ sharpTopLeftCorner }) =>
      sharpTopLeftCorner &&
      css`
        & thead tr:first-child th:first-child {
          border-top-left-radius: 0;
        }
      `}
    ${({ sharpTopRightCorner }) =>
      sharpTopRightCorner &&
      css`
        & thead tr:first-child th:first-child {
          border-top-right-radius: 0;
        }
      `}
    ${({ sharpBottomLeftCorner }) =>
      sharpBottomLeftCorner &&
      css`
        & tbody tr:last-child td:first-child {
          border-bottom-left-radius: 0;
        }
      `}
    ${({ sharpBottomRightCorner }) =>
      sharpBottomRightCorner &&
      css`
        & tbody tr:last-child td:last-child {
          border-bottom-right-radius: 0;
        }
      `};
`;

export const ModalHeading = styled.h2`
  ${fonts.headingSmall};
  margin-bottom: ${spacing(3)};
  margin-top: ${spacing(2)};

  ${mediaQuery.desktop`
    margin-top: ${spacing(3.5)};
    margin-bottom: ${spacing(4)};
  `}
`;

export const TableWrapper = styled.div`
  margin-top: ${spacing(2)};

  ${mediaQuery.tabletPortrait`
    margin-top: ${spacing(3)};
  `}

  ${StyledTableHeaderCell} {
    ${fonts.paragraph};
    height: ${spacing(7)};
    ${mediaQuery.tabletPortrait`
    height: ${spacing(9.5)};
    & {
      padding: ${spacing(2)} ${spacing(1)};
    }
  `}
    & {
      background: ${colors.core01};
      padding: ${spacing(1.75)} ${spacing(1)};
      div {
        font-weight: 400;
      }
    }
  }
`;

export const TableTitle = styled.div`
  ${fonts.paragraph}
  && {
    color: ${colors.neutral08};
    font-weight: bold;
  }
`;
export const TableSubTitle = styled.p`
  font-size: ${spacing(1.8)};
  && {
    margin: ${spacing(1)} ${spacing(1)} 0 ${spacing(1)};
    color: ${colors.neutral08};
    font-weight: 300;
    line-height: 1.25rem;
  }
  ${mediaQuery.desktop`
  ${fonts.paragraphSmall}; 
  `}
  ${mediaQuery.tabletLandscape`
    ${fonts.paragraphSmall};
  `}
  ${mediaQuery.tabletPortrait`
    ${fonts.paragraphSmall};
  `}
`;
const cellStyling = css`
  ${fonts.paragraphSmall}
  padding-left: ${spacing(1)};
  padding-right: ${spacing(1)};

  ${mediaQuery.tabletPortrait`
    padding: ${spacing(1.5)} ${spacing(2)};
  `}
`;

export const PaddedTableCell = styled(TableCell)`
  ${cellStyling}
  && {
    font-weight: 300;
  }
  height: ${spacing(7)};
  width: 33.33%;
`;

export const ColumnHeadingCell = styled(TableCell)`
  ${cellStyling}
  text-align: center;
  && {
    height: ${spacing(7)};
    background: ${colors.neutral08};
  }
`;

export const RowHeadingCell = styled(TableCell)`
  ${cellStyling}
  && {
    background: ${colors.neutral08};
    text-align: center;
    font-weight: bold;
  }

  div {
    word-break: break-word;
  }

  ${mediaQuery.tabletPortrait`
    div {
      word-break: normal;
    }
  `}
`;

export const MobileAccordion = styled(SecondaryAccordion)`
  margin-bottom: ${spacing(6)};
  margin-top: ${spacing(3)};

  ${mediaQuery.tabletPortrait`
    margin-top: ${spacing(1)};
  `}

  ${mediaQuery.desktop`
    margin-top: 0;
    display: none;
  `}

    table {
    table-layout: auto;
    margin-bottom: ${spacing(2)};
    max-width: ${spacing(68)};
  }
  ${InnerSection} {
    border: 0 none;
    padding-bottom: 0;
    ${SectionButton} {
      & {
        padding-bottom: ${spacing(1.5)};
        border-bottom: 1px solid ${colors.neutral04};
      }
      &:hover,
      &:active {
        background-color: ${colors.neutral08};
      }
    }
  }
`;

export const DesktopAccordion = styled(SecondaryAccordion)`
  display: none;

  ${mediaQuery.desktop`
    display: flex;
  `}

  margin-top: ${spacing(1)};
  margin-bottom: ${spacing(6)};

  ${TableWrapper} {
    display: flex;
     flex-direction: column;
  }

  table {
    table-layout: auto;
    margin-bottom: ${spacing(3)};
    max-width: ${spacing(68)};
  }
  table:last-child {
    margin-bottom: 0;
  }

  tbody td {
    div {
      min-height: 2rem;
      align-items: center;
    }
  }

    ${InnerSection} {
    border: 0 none;
    padding-bottom: 0;
    ${SectionButton} {
      & {
        padding-bottom: ${spacing(1.5)};
        border-bottom : 1px solid ${colors.neutral04};
      }
      &:hover,
      &:active {
        background-color: ${colors.neutral08};
      }
    }
  }
`;

export const GridWithBottomMargin = styled(Grid)`
  & {
    ${mediaQuery.tabletPortrait`
      margin-bottom: ${spacing(3)};
    `}

    ${mediaQuery.desktop`
      margin-bottom: ${spacing(4)};
    `}
  }
`;

export const CORE_COVER = 'NWA_PET_9382';

export enum CoverLevel {
  ACCIDENT_ONLY = 'NWA_PET_01',
  BASIC_1500 = 'NWA_PET_02',
  BASIC_3000 = 'NWA_PET_03',
  CLASSIC_4000 = 'NWA_PET_04',
  CLASSIC_8000 = 'NWA_PET_05',
  PREMIER_1000 = 'NWA_PET_a',
  PREMIER_2000 = 'NWA_PET_b',
  PREMIER_4000 = 'NWA_PET_c',
  PREMIER_6000 = 'NWA_PET_d',
}

export enum VetBillsAccidentsAndIllness {
  SHORT_TERM = 'Short_Term_Treatments',
  ONGOING = 'Ongoing_Treatments',
}

export enum VoluntaryExcessAmount {
  Excess_0_Percent = '0000',
  Excess_10_Percent = '010P',
  Excess_20_Percent = '020P',
}

export enum VoluntaryExcessPercentage {
  Excess_0_Percent = '0000',
  Excess_10_Percent = '010P',
  Excess_20_Percent = '020P',
}

export enum ProductId {
  DIRECT = '0000000071',
  GO_COMPARE = '0000000074',
  MONEY_SUPERMARKET = '0000000077',
  COMPARE_THE_MARKET = '0000000075',
}

export enum AddOn {
  LOST_AND_FOUND = 'NWA_PET_9388',
  PETS_ABROAD = 'NWA_PET_9389',
  FAREWELL = 'NWA_PET_9425',
}

export enum DesignConstancts {
  PET_BREED_DROPDOWN_MENU_MAX_HEIGHT = '412',
}

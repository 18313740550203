import FooterWithContactSection from '@rsa-digital/evo-shared-components/components/Footer/FooterWithContactSection';
import GridContainer from '@rsa-digital/evo-shared-components/components/GridContainer';
import React, { useEffect } from 'react';
import Analytics from 'components/Analytics';
import Header from 'components/Header';
import Meta from 'components/Meta';
import { QuoteAndBuyStep } from 'components/ProgressBar';
import ReCaptcha from 'components/ReCaptcha';
import SessionExpiryWrapper, {
  SessionExpiryOption,
} from 'components/SessionExpiry/SessionExpiryWrapper';
import { PageTitle, trackFooterLinkClick } from 'helpers/eventTracking';
import { reloadOneTrust } from 'helpers/oneTrustHelper';
import { useFooterData } from 'helpers/useFooterData';
import RefreshButton from './refresh';

/* istanbul ignore next */
const activeEnv = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development';

const Layout: React.FC<{
  currentStep?: QuoteAndBuyStep;
  metaTitle: string;
  pageTitle: PageTitle;
  sessionExpiryOption?: SessionExpiryOption;
}> = ({
  children,
  currentStep,
  metaTitle,
  pageTitle,
  sessionExpiryOption = SessionExpiryOption.DEFAULT_SESSION_EXPIRY,
}) => {
  useEffect(() => {
    reloadOneTrust();
  }, []);

  const { footerCompliance, linkSection, contactSection } = useFooterData();

  return (
    <SessionExpiryWrapper pageTitle={pageTitle} sessionExpiryOption={sessionExpiryOption}>
      <ReCaptcha />
      {activeEnv !== 'test' && <Analytics />}
      {activeEnv === 'preview' && /* istanbul ignore next */ <RefreshButton />}
      <Meta metaTitle={metaTitle} />
      <GridContainer as="main">
        <Header pageTitle={pageTitle} currentStep={currentStep} />
        {children}
        <GridContainer as="footer">
          <FooterWithContactSection
            footerCompliance={footerCompliance}
            linkSection={linkSection}
            contactSection={contactSection}
            onRichTextLinkClick={trackFooterLinkClick(pageTitle)}
          />
        </GridContainer>
      </GridContainer>
    </SessionExpiryWrapper>
  );
};

export default Layout;

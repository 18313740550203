// eslint-disable-next-line import/no-extraneous-dependencies
import { useLocation } from '@reach/router';
import { GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import ImageComponent from '@rsa-digital/evo-shared-components/components/Image';
import { graphql } from 'gatsby';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Layout from 'components/Layout';
import { SessionExpiryOption } from 'components/SessionExpiry/SessionExpiryWrapper';
import { CsBlock, mapBlocks } from 'helpers/blockMapper';
import { processImageAsset } from 'helpers/csTypeProcessors';
import { PageTitle, trackErrorPage } from 'helpers/eventTracking';
import { petNameReplacer } from 'helpers/placeholders/dynamicPetNameHelpers';
import { quotePlaceholders } from 'helpers/placeholders/quotePlaceholders';
import { replacePlaceholdersPlainText } from 'helpers/placeholders/replaceCsPlaceholders';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { useMetaTitle } from 'helpers/useMetaTitle';
import { RootState } from 'state/createStore';
import { ErrorType } from 'state/error/actions';
import { usePetsDetails } from 'state/formData/petsDetails';
import { CsAsset } from 'types/contentStack';
import {
  Container,
  Heading,
  MobileSubhead,
  StyledMobileSubheading,
  StyledSubheading,
  Subhead,
} from './styles';

export type ErrorPageProps = {
  data: ErrorPageData;
  sessionExpiryOption?: SessionExpiryOption;
  pageTitle?: PageTitle;
};

export type ErrorPageData = {
  csErrorPage: {
    meta_title: string;
    heading: string;
    subhead?: string;
    block_subheading?: string;
    image: CsAsset | null;
    blocks: CsBlock[];
  };
};

export const query = graphql`
  fragment ErrorPage on cs__error_page {
    meta_title
    heading
    subhead
    block_subheading
    image {
      ...CsAsset
    }
    blocks {
      ...IntroBlockError
      ...ActionCardsBlockError
      ...CtaBannerBlockError
      ...ProductCardsBlockError
      ...AccordionBlockError
      ...CtaBlockError
    }
  }
`;

const convertErrorTypeToUrl = (errorType: ErrorType): string =>
  errorType.toLowerCase().replace(/_/g, '-');

const ErrorPage: React.FC<ErrorPageProps> = ({
  data,
  sessionExpiryOption = SessionExpiryOption.DEFAULT_SESSION_EXPIRY,
  pageTitle = PageTitle.TechnicalError,
}) => {
  const page = data.csErrorPage;
  const { pathname } = useLocation();
  const error = useSelector((state: RootState) => state.error);
  const getFullMetaTitle = useMetaTitle();
  const processedImage = processImageAsset(page.image);
  const quote = useCurrentQuote();
  const petDetails = usePetsDetails()[0];
  const petNames = petDetails.map((pet) => pet.petName).join(', ');
  const errorMessageReplacer = (csString: string): string =>
    petNameReplacer(
      petNames,
      replacePlaceholdersPlainText(quotePlaceholders, quote, true)(csString)
    );
  const headings = (
    <>
      <Heading data-cy="ErrorHeading">{errorMessageReplacer(page.heading)}</Heading>
      {page.subhead && <Subhead>{errorMessageReplacer(page.subhead)}</Subhead>}
      {!page.subhead && page.block_subheading && (
        <MobileSubhead
          html={errorMessageReplacer(page.block_subheading)}
          data-cy="Mobile block subheading"
        />
      )}
    </>
  );

  const errorClassification =
    pageTitle === PageTitle.BusinessError ? 'business-error' : 'technical-error';

  useEffect(() => {
    if (error.errorType) {
      trackErrorPage(
        `${pathname}${errorClassification}/${convertErrorTypeToUrl(error.errorType)}`,
        getFullMetaTitle(data.csErrorPage.meta_title),
        quote,
        error
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error.errorType, errorClassification]);

  return (
    <Layout
      pageTitle={pageTitle}
      metaTitle={page.meta_title}
      sessionExpiryOption={sessionExpiryOption}>
      {processedImage ? (
        <>
          {headings}
          <Container>
            <GridItem desktop={4} tabletLandscape={4} tabletPortrait={4}>
              <ImageComponent image={processedImage} />
            </GridItem>
            <GridItem desktop={6} tabletLandscape={6} tabletPortrait={4}>
              {page.block_subheading && (
                <StyledSubheading html={errorMessageReplacer(page.block_subheading)} />
              )}
              {page.block_subheading && page.subhead && (
                <StyledMobileSubheading
                  html={errorMessageReplacer(page.block_subheading)}
                  data-cy="Styled Mobile Subhead"
                />
              )}
              {page.blocks &&
                mapBlocks(page.blocks, pageTitle, { useCompactProductCards: false })}
            </GridItem>
          </Container>
        </>
      ) : (
        headings
      )}
    </Layout>
  );
};

export default ErrorPage;

import { Quote } from 'apiHelpers/quote/quoteResponse';
import { RESET_STATE, ResetStateAction } from 'state/actions';

export const UPDATE_QUOTE = 'UPDATE_QUOTE';

export type UpdateQuoteAction = {
  type: typeof UPDATE_QUOTE;
  quote: Quote | null;
};

export const quoteReducer = (
  quote: Quote | null = null,
  action: UpdateQuoteAction | ResetStateAction
): Quote | null => {
  switch (action.type) {
    case UPDATE_QUOTE:
      return (
        action.quote && {
          ...action.quote,
        }
      );
    case RESET_STATE:
      return null;
    default:
      return quote;
  }
};

import { parseDate } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';

export const getFullYears = (dob: Date): number => {
  const today = new Date();
  const years = today.getFullYear() - dob.getFullYear();
  if (
    dob.getMonth() > today.getMonth() ||
    (dob.getMonth() === today.getMonth() && dob.getDate() > today.getDate())
  ) {
    return years - 1;
  }

  return years;
};

export const getFullMonths = (dob: Date): number => {
  const today = new Date();
  const monthDifference = today.getMonth() - dob.getMonth();
  const months = monthDifference < 0 ? 12 + monthDifference : monthDifference;

  if (dob.getDate() > today.getDate()) {
    return months === 0 ? 11 : months - 1;
  }
  return months;
};

const getYearText = (years: number): string => {
  if (years === 0) {
    return '';
  }
  if (years === 1) {
    return `${years} year`;
  }
  return `${years} years`;
};

const getMonthText = (months: number): string => {
  if (months === 0) {
    return '';
  }
  if (months === 1) {
    return `${months} month`;
  }
  return `${months} months`;
};

/**
 * Formats age like `1 year, 1 month`
 */
export const getAgeShort = (dateOfBirth: Date | string): string => {
  const dob = parseDate(dateOfBirth);
  const today = new Date();

  if (dob > today) throw new Error('Date of birth in future');

  const years = getFullYears(dob);
  const months = getFullMonths(dob);

  if (years === 0 && months === 0) {
    return '0 months';
  }

  if (months === 0) {
    return getYearText(years);
  }

  if (years === 0) {
    return getMonthText(months);
  }

  return `${getYearText(years)}, ${getMonthText(months)}`;
};

/**
 * Any date of birth with a year earlier than 1900 i.e 31/12/1899 will throw an error in AIS
 */
export const isAfterMinValidDate = (dateOfBirth: Date | null): boolean => {
  if (dateOfBirth === null) {
    return false;
  }
  const maxDate = new Date(1900, 0, 1);

  return dateOfBirth >= maxDate;
};

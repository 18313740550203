import React from 'react';
import { booleanAttribute } from './booleanAttribute';

const optimizelyScript: JSX.Element[] = [
  <script
    src="https://cdn.optimizely.com/js/22414190602.js"
    type="text/plain"
    className="optanon-category-C0002-C0004"
    async={booleanAttribute}
    defer={booleanAttribute}
    key="optimizelyScript"
  />,
];

export default optimizelyScript;

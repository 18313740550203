import { StateInfo } from '@rsa-digital/evo-shared-components/components/AddOnChooser/types';
import { graphql, useStaticQuery } from 'gatsby';
import { useSelector } from 'react-redux';
import { processPanelState } from 'helpers/csTypeProcessors';
import { RootState } from 'state/createStore';
import { CsPanelState } from 'types/contentStack';

export const query = graphql`
  query {
    csPetAddOnsConfig {
      monthly_subheading_single_pet
      monthly_subheading_multipet
      yearly_subheading_single_pet
      yearly_subheading_multipet
      button_text {
        accept_cover
        decline_cover
        see_more_details
      }
      cover_added_state {
        bottom_label_text
        corner_label {
          text
          icon {
            icon_code
          }
        }
      }
      cover_errored_state {
        bottom_label_text
        corner_label {
          text
          icon {
            icon_code
          }
        }
      }
      cover_declined_state {
        bottom_label_text
        corner_label {
          icon {
            icon_code
          }
          text
        }
      }
    }
  }
`;

export type CsAddOnsConfig = {
  csPetAddOnsConfig: {
    monthly_subheading_single_pet: string;
    monthly_subheading_multipet: string;
    yearly_subheading_single_pet: string;
    yearly_subheading_multipet: string;
    button_text: {
      accept_cover: string | null;
      decline_cover: string | null;
      see_more_details: string | null;
    };
    cover_added_state: CsPanelState;
    cover_errored_state: CsPanelState;
    cover_declined_state: CsPanelState;
  };
};

export const useAddOnChooserContent = (): {
  monthlySubheading: string;
  annualSubheading: string;
  stateInfo: StateInfo;
  buttonText: {
    seeMoreDetails: string | undefined;
    declineCover: string | undefined;
    acceptCover: string | undefined;
  };
} => {
  const { csPetAddOnsConfig } = useStaticQuery<CsAddOnsConfig>(query);

  const storedQuote = useSelector((state: RootState) => state.quote);

  return {
    monthlySubheading:
      storedQuote?.petInfos.length === 1
        ? csPetAddOnsConfig.monthly_subheading_single_pet
        : csPetAddOnsConfig.monthly_subheading_multipet,
    annualSubheading:
      storedQuote?.petInfos.length === 1
        ? csPetAddOnsConfig.yearly_subheading_single_pet
        : csPetAddOnsConfig.yearly_subheading_multipet,
    stateInfo: {
      selected: processPanelState(csPetAddOnsConfig.cover_added_state),
      declined: processPanelState(csPetAddOnsConfig.cover_declined_state),
      errored: processPanelState(csPetAddOnsConfig.cover_errored_state),
    },
    buttonText: {
      seeMoreDetails: csPetAddOnsConfig.button_text.see_more_details || undefined,
      declineCover: csPetAddOnsConfig.button_text.decline_cover || undefined,
      acceptCover: csPetAddOnsConfig.button_text.accept_cover || undefined,
    },
  };
};

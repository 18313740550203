import {
  addYearsToDate,
  formatNumericDate,
} from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { formatInPounds, formatInPoundsWithSign } from 'helpers/poundsHelpers';
import { CurrentQuote } from 'helpers/useCurrentQuote';
import { combinePetNames } from './combinePetNames';
import { CsPlaceholders } from './replaceCsPlaceholders';

export const quotePlaceholders: CsPlaceholders<CurrentQuote> = {
  petNames: {
    getSubstitutionText: (quote) => combinePetNames(quote),
    isPii: true,
  },
  monthlyPaymentAmount: {
    getSubstitutionText: (quote) => formatInPounds(quote.price?.monthlyPrice.total),
    isPii: false,
  },
  monthlyTotalAmount: {
    getSubstitutionText: (quote) => {
      const monthlyAmount = quote.price?.monthlyPrice.total;
      return formatInPounds(monthlyAmount && monthlyAmount * 12);
    },
    isPii: false,
  },
  annualPaymentAmount: {
    getSubstitutionText: (quote) => formatInPounds(quote.price?.annualPrice.total),
    isPii: false,
  },
  customerEmail: {
    getSubstitutionText: (quote) => quote.customerInfo?.email ?? '',
    isPii: true,
  },
  customerFirstName: {
    getSubstitutionText: (quote) => quote.customerInfo?.firstName ?? '',
    isPii: true,
  },
  basicCoverLimit: {
    getSubstitutionText: (quote) =>
      formatInPoundsWithSign(quote.quoteOptions.basicCoverFeeLimit),
    isPii: false,
  },
  classicCoverLimit: {
    getSubstitutionText: (quote) =>
      formatInPoundsWithSign(quote.quoteOptions.classicCoverFeeLimit),
    isPii: false,
  },
  premierCoverLimit: {
    getSubstitutionText: (quote) =>
      formatInPoundsWithSign(quote.quoteOptions.premierCoverFeeLimit),
    isPii: false,
  },
  quoteReference: {
    getSubstitutionText: (quote) => quote.policyInfo?.quoteNumber ?? '',
    isPii: false,
  },
  quoteExpiryDate: {
    getSubstitutionText: (quote) =>
      quote.policyInfo?.quoteExpiryDate
        ? formatNumericDate(new Date(quote.policyInfo?.quoteExpiryDate))
        : '',
    isPii: false,
  },
  coverStartDate: {
    getSubstitutionText: (quote) =>
      quote.policyInfo?.coverStartDate
        ? formatNumericDate(new Date(quote.policyInfo?.coverStartDate))
        : '',
    isPii: false,
  },
  paymentRegularity: {
    getSubstitutionText: (quote) =>
      quote.quoteOptions.isAnnualPayment ? 'annual' : 'monthly',
    isPii: false,
  },
  coverRenewalDate: {
    getSubstitutionText: (quote) =>
      quote.policyInfo?.coverStartDate
        ? formatNumericDate(addYearsToDate(new Date(quote.policyInfo?.coverStartDate), 1))
        : '',
    isPii: false,
  },
  perPetText: {
    getSubstitutionText: (quote) => ((quote.petInfos?.length || 0) > 1 ? 'per pet' : ''),
    isPii: false,
  },
  forThatPetText: {
    getSubstitutionText: (quote) =>
      (quote.petInfos?.length || 0) > 1 ? ' for that pet' : '',
    isPii: false,
  },
};

import { getCoverLevelFromQuoteOptions } from 'apiHelpers/quote/bundleCoverMapping';
import { BundleCover } from 'apiHelpers/quote/quoteResponse';
import { graphql, useStaticQuery } from 'gatsby';
import { useSelector } from 'react-redux';
import { getBundleCovers } from 'components/QuoteSummary/AddOnsSection';
import { AddOn } from 'helpers/businessConstants';
import { getProductFromQuoteOptions, Product } from 'helpers/productHelpers';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { RootState } from 'state/createStore';
import { CsAddOn } from 'types/contentStack';

export type AddOnType = 'lostAndFound' | 'petsAbroad' | 'farewell';

export const addOnMapping: Record<AddOnType, AddOn> = {
  lostAndFound: AddOn.LOST_AND_FOUND,
  petsAbroad: AddOn.PETS_ABROAD,
  farewell: AddOn.FAREWELL,
};

export const getAddOnTypes = (): AddOnType[] => Object.keys(addOnMapping) as AddOnType[];

export const getAddOnCoverCodes = (): AddOn[] => Object.values(addOnMapping);

export const getAddOnCoverCodeFromType = (type: AddOnType): AddOn | undefined =>
  addOnMapping[type];

export const getAddOnTypeFromCoverCode = (coverCode: AddOn): AddOnType | undefined =>
  Object.keys(addOnMapping).find(
    (type) => addOnMapping[type as AddOnType] === coverCode
  ) as AddOnType | undefined;

export type AddOnPrices = {
  coverCode: AddOn;
  annualPricePerPet: number[];
  monthlyPricePerPet: number[];
};

export type AddOnDetails = { content: CsAddOn; cover: BundleCover }[];

type CsAddOns = {
  allCsPetAddOn: {
    nodes: CsAddOn[];
  };
};

export const query = graphql`
  query {
    allCsPetAddOn {
      nodes {
        type
        icon {
          icon_code
        }
        heading
        label
        summary
        display_add_on_for_product {
          product_selector
        }
      }
    }
  }
`;

export const useAddOnsForSelectedProduct = (): {
  addOnsForSelectedProduct: AddOnDetails;
  getValidProductsForAddOn: (type: AddOnType) => Product[];
} => {
  const {
    allCsPetAddOn: { nodes: addOns },
  } = useStaticQuery<CsAddOns>(query);
  const quote = useSelector((state: RootState) => state.quote);
  const { quoteOptions } = useCurrentQuote();
  const coverLevel = getCoverLevelFromQuoteOptions(quoteOptions);
  const bundleCovers = quote ? getBundleCovers(quote, coverLevel) : undefined;
  const selectedProduct = getProductFromQuoteOptions(quoteOptions);

  const getAddOnDetailsForSelectedProduct = (): AddOnDetails =>
    addOns
      .filter(
        (addOn) =>
          selectedProduct &&
          addOn.display_add_on_for_product.product_selector.includes(selectedProduct)
      )
      .map((addOn) => {
        const addOnCover = bundleCovers?.find(
          (cover) =>
            getAddOnCoverCodeFromType(addOn.type as AddOnType) === cover.cover.name
        );

        if (!addOnCover) {
          return undefined;
        }

        return {
          content: addOn,
          cover: addOnCover,
        };
      })
      .filter(Boolean) as AddOnDetails;

  const addOnsForSelectedProduct = getAddOnDetailsForSelectedProduct();

  const getValidProductsForAddOn = (type: AddOnType): Product[] =>
    addOns.find((addOn) => addOn.type === type)?.display_add_on_for_product
      .product_selector ?? [];

  return {
    addOnsForSelectedProduct,
    getValidProductsForAddOn,
  };
};

import axios, { AxiosRequestConfig } from 'axios';
import { stringify } from 'query-string';
import { getTransactionId } from 'helpers/transactionIdHelpers';

const petApiUrl = process.env.GATSBY_PET_API_URL;

type QueryParams = Record<string, string | boolean | undefined>;

type ApiService = {
  get: <T>(path: string, queryParams?: QueryParams) => Promise<T>;
  put: <T = void>(path: string, body: unknown, queryParams?: QueryParams) => Promise<T>;
  post: <T>(path: string, body: unknown, queryParams?: QueryParams) => Promise<T>;
  delete: <T = void>(path: string, queryParams?: QueryParams) => Promise<T>;
};

const getSerialisedQueryParams = (queryParams?: QueryParams): string => {
  // Undefined query params are automatically filtered out by 'stringify'
  const stringifiedQueryParams = queryParams ? stringify(queryParams) : '';
  return stringifiedQueryParams ? `?${stringifiedQueryParams}` : '';
};

const apiService = (baseUrl?: string): ApiService => {
  const transactionId = getTransactionId();

  /*  We configure axios to timeout after 59 seconds to avoid false positive CORS errors
  from a backend that froze. The AWS load balancer has a default timeout value of 60 seconds
  before sending a response with no Access-Control-Allow-Origin header, resulting in a CORS error */
  const defaultAxiosRequestConfig: Partial<AxiosRequestConfig> = {
    timeout: 59000,
  };

  return {
    get: async <T>(path: string, queryParams?: QueryParams): Promise<T> => {
      const serialisedQueryParams = getSerialisedQueryParams(queryParams);
      const result = await axios.get(`${baseUrl}/${path}${serialisedQueryParams}`, {
        ...defaultAxiosRequestConfig,
        headers: {
          TransactionId: transactionId,
        },
      });
      return result.data;
    },
    put: async <T = void>(
      path: string,
      body: unknown,
      queryParams?: QueryParams
    ): Promise<T> => {
      const serialisedQueryParams = getSerialisedQueryParams(queryParams);
      const result = await axios.put(`${baseUrl}/${path}${serialisedQueryParams}`, body, {
        ...defaultAxiosRequestConfig,
        headers: {
          TransactionId: transactionId,
        },
      });
      return result.data;
    },
    post: async <T>(
      path: string,
      body: unknown,
      queryParams?: QueryParams
    ): Promise<T> => {
      const serialisedQueryParams = getSerialisedQueryParams(queryParams);
      const result = await axios.post(
        `${baseUrl}/${path}${serialisedQueryParams}`,
        JSON.stringify(body),
        {
          ...defaultAxiosRequestConfig,
          headers: {
            'Content-Type': 'application/json',
            TransactionId: transactionId,
          },
        }
      );
      return result.data;
    },
    delete: async <T = void>(path: string, queryParams?: QueryParams): Promise<T> => {
      const serialisedQueryParams = getSerialisedQueryParams(queryParams);
      const result = await axios.delete(`${baseUrl}/${path}${serialisedQueryParams}`, {
        ...defaultAxiosRequestConfig,
        headers: {
          TransactionId: transactionId,
        },
      });
      return result.data;
    },
  };
};

export const petApiService = apiService(petApiUrl);

import {
  CatBreedsReferenceData,
  CatBreedsReferenceDataResponse,
  mapCatBreedsRefData,
} from 'apiHelpers/referenceData/catBreeds';
import { petApiService } from './apiService';
import {
  CountiesReferenceData,
  CountiesReferenceDataResponse,
  mapCountiesRefData,
} from './referenceData/counties';
import {
  DaysReferenceData,
  DaysReferenceDataResponse,
  mapDaysRefData,
} from './referenceData/days';
import {
  DogBreedsReferenceData,
  DogBreedsReferenceDataResponse,
  mapDogBreedsRefData,
} from './referenceData/dogBreeds';
import {
  GendersReferenceData,
  GendersReferenceDataResponse,
  mapGendersRefData,
} from './referenceData/genders';
import {
  mapPetCoverLevelRefData,
  PetCoverLevelReferenceData,
  PetCoverLevelReferenceDataResponse,
} from './referenceData/petCoverLevel';
import {
  mapTitlesRefData,
  TitlesReferenceData,
  TitlesReferenceDataResponse,
} from './referenceData/titles';

type ReferenceDataClient = {
  catBreeds: () => Promise<CatBreedsReferenceData>;
  dogBreeds: () => Promise<DogBreedsReferenceData>;
  genders: () => Promise<GendersReferenceData>;
  titles: () => Promise<TitlesReferenceData>;
  counties: () => Promise<CountiesReferenceData>;
  days: () => Promise<DaysReferenceData>;
  petCoverLevel: () => Promise<PetCoverLevelReferenceData>;
};

const referenceDataClient: ReferenceDataClient = {
  catBreeds: () =>
    petApiService
      .get<CatBreedsReferenceDataResponse>('category/catbreed')
      .then(mapCatBreedsRefData),
  dogBreeds: () =>
    petApiService
      .get<DogBreedsReferenceDataResponse>('category/dogbreed')
      .then(mapDogBreedsRefData),
  genders: () =>
    petApiService
      .get<GendersReferenceDataResponse>('category/gender')
      .then(mapGendersRefData),
  titles: () =>
    petApiService
      .get<TitlesReferenceDataResponse>('category/restrictions_title')
      .then(mapTitlesRefData),
  counties: () =>
    petApiService
      .get<CountiesReferenceDataResponse>('category/county')
      .then(mapCountiesRefData),
  days: () =>
    petApiService.get<DaysReferenceDataResponse>('category/day').then(mapDaysRefData),
  petCoverLevel: () =>
    petApiService
      .get<PetCoverLevelReferenceDataResponse>('category/petcoverlevel')
      .then(mapPetCoverLevelRefData),
};

export default referenceDataClient;

import { AccordionEntry } from '@rsa-digital/evo-shared-components/components/Accordion';
import {
  Grid,
  GridItem,
  GridItemProps,
} from '@rsa-digital/evo-shared-components/components/Grid';
import GridContainer from '@rsa-digital/evo-shared-components/components/GridContainer';
import Modal, { ModalProps } from '@rsa-digital/evo-shared-components/components/Modal';
import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import fluidGridColumns from '@rsa-digital/evo-shared-components/theme/fluidGridColumns';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import {
  PageTitle,
  trackAccordionClick,
  trackModalLinkClick,
  trackRichTextLinkClick,
} from 'helpers/eventTracking';
import {
  ColumnHeadingCell,
  DesktopAccordion,
  GridWithBottomMargin,
  MobileAccordion,
  ModalHeading,
  PaddedTableCell,
  RowHeadingCell,
  TableSubTitle,
  TableTitle,
  TableWithOptionalSharpCorners,
  TableWrapper,
} from './styles';

type TreamentCostModalProps = { pageTitle: PageTitle } & ModalProps & ComponentProps;

type CsContentRow = {
  content_row: {
    treatment: string;
    average: string;
    most_expensive: string;
  };
};

type CsAccordionSection = {
  accordion_section: {
    section_heading: string;
    section_subheading: string;
    accident_table: CsContentRow[];
    short_term_illness_table: CsContentRow[];
    ongoing_illness_table: CsContentRow[];
  };
};

type CsTreatmentCostModal = {
  csPetTypicalTreatmentCostModal: {
    modal_heading: string;
    explanatory_text: string;
    disclaimer_text: string;
    table_headings: {
      accident: string;
      ongoing_illness: string;
      short_term_illness: string;
    };
    table_subheadings: {
      subheading_for_accident: string;
      subheading_for_ongoing_illness: string;
      subheading_for_short_term_illness: string;
    };
    column_headings: {
      average: string;
      most_expensive: string;
    };
    accordion_with_tables: CsAccordionSection[];
  };
};

const query = graphql`
  query {
    csPetTypicalTreatmentCostModal {
      modal_heading
      explanatory_text
      disclaimer_text
      table_headings {
        accident
        ongoing_illness
        short_term_illness
      }
      table_subheadings {
        subheading_for_accident
        subheading_for_ongoing_illness
        subheading_for_short_term_illness
      }
      column_headings {
        average
        most_expensive
      }
      accordion_with_tables {
        accordion_section {
          section_heading
          accident_table {
            content_row {
              treatment
              average
              most_expensive
            }
          }
          short_term_illness_table {
            content_row {
              treatment
              average
              most_expensive
            }
          }
          ongoing_illness_table {
            content_row {
              treatment
              average
              most_expensive
            }
          }
        }
      }
    }
  }
`;

type CostTableProps = {
  columnHeadings: {
    average: string;
    most_expensive: string;
  };
  showColHeadings?: boolean;
  title: string;
  subheading: string;
  contentRows: CsContentRow[];
};

const CostTable: React.FC<CostTableProps> = ({
  columnHeadings,
  showColHeadings,
  title,
  subheading,
  contentRows,
}) => (
  <TableWithOptionalSharpCorners>
    <colgroup span={3} />
    <thead>
      <tr>
        <PaddedTableCell colSpan={3} isHeader scope="colgroup">
          <TableTitle>
            {title} {subheading && <TableSubTitle>{subheading}</TableSubTitle>}
          </TableTitle>
        </PaddedTableCell>
      </tr>
      {showColHeadings && (
        <tr>
          <RowHeadingCell> </RowHeadingCell>
          <ColumnHeadingCell isHeader scope="col">
            {columnHeadings.average}
          </ColumnHeadingCell>
          <ColumnHeadingCell isHeader scope="col">
            {columnHeadings.most_expensive}
          </ColumnHeadingCell>
        </tr>
      )}
    </thead>
    <tbody>
      {contentRows.map((row, index) => {
        const highlightRow = index % 2 === 0;

        return (
          <tr key={row.content_row.treatment}>
            <RowHeadingCell highlight={highlightRow} scope="row">
              {row.content_row.treatment}
            </RowHeadingCell>
            <PaddedTableCell highlight={highlightRow}>
              {row.content_row.average}
            </PaddedTableCell>
            <PaddedTableCell highlight={highlightRow}>
              {row.content_row.most_expensive}
            </PaddedTableCell>
          </tr>
        );
      })}
    </tbody>
  </TableWithOptionalSharpCorners>
);

const TreatmentCostModal: React.FC<TreamentCostModalProps> = ({
  onClose,
  pageTitle,
  ...props
}) => {
  const content = useStaticQuery<CsTreatmentCostModal>(query)
    .csPetTypicalTreatmentCostModal;

  const generateAccordionEntries = (): AccordionEntry[] =>
    content.accordion_with_tables.map((section) => {
      const {
        section_heading,
        section_subheading,
        accident_table,
        ongoing_illness_table,
        short_term_illness_table,
      } = section.accordion_section;

      return {
        title: section_heading,
        subheading: section_subheading,
        initiallyDisplay: false,
        content: (
          <TableWrapper>
            <CostTable
              title={content.table_headings.accident}
              subheading={content.table_subheadings.subheading_for_accident}
              contentRows={accident_table}
              showColHeadings
              columnHeadings={content.column_headings}
            />
            <CostTable
              title={content.table_headings.short_term_illness}
              subheading={content.table_subheadings.subheading_for_short_term_illness}
              contentRows={short_term_illness_table}
              showColHeadings
              columnHeadings={content.column_headings}
            />
            <CostTable
              title={content.table_headings.ongoing_illness}
              subheading={content.table_subheadings.subheading_for_ongoing_illness}
              contentRows={ongoing_illness_table}
              showColHeadings
              columnHeadings={content.column_headings}
            />
          </TableWrapper>
        ),
        onExpand: () => trackAccordionClick('expand', section_heading),
        onCollapse: () => trackAccordionClick('collapse', section_heading),
      };
    });

  const textGridItemProps: GridItemProps = {
    desktop: 10,
    tabletLandscape: 10,
    tabletPortrait: 6,
  };

  return (
    <Modal
      data-cy="treatmentCostModal"
      onClose={(e) => {
        trackModalLinkClick('treatment cost modal', 'close');
        onClose?.(e);
      }}
      aria-labelledby="treatment-cost-modal-heading"
      gridItemProps={{
        desktop: fluidGridColumns.desktop,
        tabletLandscape: fluidGridColumns.tabletLandscape,
      }}
      {...componentProps(props)}>
      <GridContainer>
        <Grid alignLeft>
          <GridItem desktop={1} tabletLandscape={1} tabletPortrait={1} />
          <GridItem {...textGridItemProps}>
            <ModalHeading id="treatment-cost-modal-heading">
              {content.modal_heading}
            </ModalHeading>
          </GridItem>
        </Grid>
        <Grid>
          <GridItem {...textGridItemProps}>
            <RichText
              html={content.explanatory_text}
              onLinkClick={trackRichTextLinkClick(pageTitle, 'treatment cost modal')}
            />
          </GridItem>
        </Grid>
        <MobileAccordion
          accordionEntries={generateAccordionEntries()}
          initiallyDisplayEntries={false}
        />
        <DesktopAccordion
          accordionEntries={generateAccordionEntries()}
          initiallyDisplayEntries={false}
        />
        <GridWithBottomMargin>
          <GridItem {...textGridItemProps}>
            <RichText
              html={content.disclaimer_text}
              onLinkClick={trackRichTextLinkClick(pageTitle, 'treatment cost modal')}
            />
          </GridItem>
        </GridWithBottomMargin>
      </GridContainer>
    </Modal>
  );
};

export default TreatmentCostModal;

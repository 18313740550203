import { AxiosError } from 'axios';

const createAxiosError = (status?: number, data?: unknown): AxiosError => {
  const error: AxiosError = {
    ...new Error(),
    isAxiosError: true,
    config: {},
    toJSON: () => ({}),
  };
  return status
    ? {
        ...error,
        response: {
          status,
          data: data || null,
          statusText: '',
          config: {},
          headers: null,
        },
      }
    : error;
};

export default createAxiosError;

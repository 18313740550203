import { DateValue } from '@rsa-digital/evo-shared-components/components/Form/DateInput';
import {
  addOrdinalSuffix,
  getStartOfDay,
  longMonth,
  numericYear,
  parseDate,
} from '@rsa-digital/evo-shared-components/helpers/dateHelpers';

export const getDateOnlyIsoString = (dateIsoString: string): string =>
  dateIsoString.split('T')[0];

/**
 * Formats to a date like `1st October 2017` or `1st, October 2017`
 */
export const formatLongDateWithOrdinalSuffix = (
  date: Date | string,
  formatWithComma = false
): string => {
  const parsedDate = parseDate(date);

  return `${addOrdinalSuffix(parsedDate.getDate())}${
    formatWithComma ? ',' : ''
  } ${longMonth.format(parsedDate)} ${numericYear.format(parsedDate)}`;
};

/**
 * @param dateString Date to count to as a parseable date string
 * @returns number of days until date
 */
export const countDaysBetweenDates = (
  fromDate: string | Date,
  toDate: string | Date
): number => {
  const startOfFromDate = getStartOfDay(new Date(fromDate));
  const startOfToDate = getStartOfDay(new Date(toDate));

  const differenceInMs = startOfToDate.getTime() - startOfFromDate.getTime();
  const differenceInDays = differenceInMs / (1000 * 60 * 60 * 24);

  return Math.floor(differenceInDays);
};

export const areDateValuesEqual = (
  dateValue1: DateValue,
  dateValue2: DateValue
): boolean => {
  return (
    dateValue1.day === dateValue2.day &&
    dateValue1.month === dateValue2.month &&
    dateValue1.year === dateValue2.year
  );
};

import { FieldFunction } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { getBundleFromQuote } from 'apiHelpers/quote/bundleCoverMapping';
import { QuoteRequestPetInfo } from 'apiHelpers/quote/quoteRequest';
import { BundleCover, Quote } from 'apiHelpers/quote/quoteResponse';
import { AddOnDetails, AddOnType } from 'businessLogic/addOns';
import { flatMap } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import AddOnChooser from 'components/AddOnChooser';
import useTwentyPercentExcessAgreements from 'components/QuoteSummary/ExcessSection/AccidentAndIllnessChooseExcessSection/useTwentyPercentExcessAgreements';
import { CORE_COVER, CoverLevel } from 'helpers/businessConstants';
import { PageTitle } from 'helpers/eventTracking';
import { useCurrentQuote, useUpdateQuoteOptions } from 'helpers/useCurrentQuote';
import { RootState } from 'state/createStore';
import { AddOnsSelected } from 'state/formData/quoteOptions';

export const getBundleCovers = (
  quote: Quote,
  coverLevel: CoverLevel | undefined
): BundleCover[] => {
  const bundle = getBundleFromQuote(coverLevel, quote);

  if (!bundle) {
    return [];
  }

  return flatMap(quote.parentCover, (parentCoverCode) =>
    bundle.covers.filter(
      (cover) =>
        parentCoverCode !== CORE_COVER &&
        (cover.coverCode === parentCoverCode || cover.cover.name === parentCoverCode)
    )
  );
};

const AddOnsSection: React.FC<{
  addOns: AddOnDetails;
  getError: FieldFunction<AddOnsSelected, string | undefined>;
  pageTitle: PageTitle;
}> = ({ addOns, getError, pageTitle }) => {
  const updateQuoteOptions = useUpdateQuoteOptions();
  const { quoteOptions } = useCurrentQuote();
  const quote = useSelector((state: RootState) => state.quote);
  const { updateTwentyPercentExcessAgreement } = useTwentyPercentExcessAgreements();
  return (
    <>
      {addOns.map((addOn) => {
        const { content, cover } = addOn;

        const onUpdateSelection = async (selected: boolean): Promise<void> => {
          updateQuoteOptions({
            addOnsSelected: {
              ...quoteOptions.addOnsSelected,
              [content.type]: selected,
            },
          });
          quote?.petInfos?.map((pet: QuoteRequestPetInfo, i: number) => {
            updateTwentyPercentExcessAgreement(undefined, i);
            return pet;
          });
        };

        return (
          <AddOnChooser
            key={content.type}
            id={content.type}
            addOnCover={cover}
            isSelected={quoteOptions.addOnsSelected[content.type as AddOnType]}
            validateSelection={!!getError(content.type as AddOnType)}
            addOnContent={content}
            onUpdateSelection={onUpdateSelection}
            pageTitle={pageTitle}
          />
        );
      })}
    </>
  );
};

export default AddOnsSection;

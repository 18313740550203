import { SplitInfoAccordionCard } from '@rsa-digital/evo-shared-components/components/SplitInfoCard';
import { graphql } from 'gatsby';
import React from 'react';
import { unwrapSingleton } from 'helpers/csTypeProcessors';
import { CsIcon } from 'types/contentStack';

export type AccordionSplitInfoCardBlockProps = {
  icon: [CsIcon];
  heading: string;
  body: string;
  phone_number: string;
  opening_hours: string;
};

export const query = graphql`
  fragment SplitInfoCardAccordionEntryErrorAccordion on cs__error_page_blocksaccordion_accordion_entriesaccordion_entry_content {
    split_info_card {
      icon {
        icon_code
      }
      heading
      body
      phone_number
      opening_hours
    }
  }
`;

const AccordionSplitInfoCardBlock: React.FC<{
  split_info_card: AccordionSplitInfoCardBlockProps;
}> = ({ split_info_card }) => {
  const icon = unwrapSingleton(split_info_card.icon);
  /* istanbul ignore if */
  if (!icon) {
    throw new Error('Icon for Split Info Card missing!');
  }

  return (
    <SplitInfoAccordionCard
      data-cy="SplitInfoAccordionCard"
      icon={icon.icon_code}
      heading={split_info_card.heading}
      body={split_info_card.body}
      phoneNumber={split_info_card.phone_number}
      openingHours={split_info_card.opening_hours}
    />
  );
};

export default React.memo(AccordionSplitInfoCardBlock);

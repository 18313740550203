import {
  addDaysToDate,
  addYearsToDate,
  dateValueToUtcDate,
  localDateToUtcDate,
} from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { usePetRules } from 'forms/AboutYourPetsForm/AboutYourPet/validation';
import { Pet, usePetsDetails } from 'state/formData/petsDetails';

export const YOUNG_PET_AGE_THRESHOLD_DAYS = 56;

const getYoungestPetDob = (pets: Pet[]): Date | null => {
  const petDates = pets.map((pet) => dateValueToUtcDate(pet.petDob));
  return petDates.reduce((d1, d2) => {
    if (!d1) {
      return d2;
    }
    if (!d2) {
      return d1;
    }
    return d1 > d2 ? d1 : d2;
  }, null);
};

export const getYoungestPetAgeThresholdDate = (pets: Pet[]): Date | null => {
  const youngestPetDob = getYoungestPetDob(pets);
  return youngestPetDob && addDaysToDate(youngestPetDob, YOUNG_PET_AGE_THRESHOLD_DAYS);
};

export const isPetAlreadyAgeNineForCover = (
  petDob: string,
  coverStartDate: string
): boolean => addYearsToDate(new Date(petDob), 9) <= new Date(coverStartDate);

export const useFirstEligibleStartDateFromToday = (): [Date, boolean] => {
  const {
    errors: { petDob: isPetDobValid },
  } = usePetRules();
  const [petsDetails] = usePetsDetails();
  const firstEligibleStartDate = getYoungestPetAgeThresholdDate(
    petsDetails.filter(
      (pet) => !isPetDobValid?.some((rule) => !rule.validityCondition(pet.petDob, pet))
    )
  );
  const todayUtc = localDateToUtcDate(new Date());
  const minDate =
    !!firstEligibleStartDate && firstEligibleStartDate > todayUtc
      ? firstEligibleStartDate
      : todayUtc;
  return [minDate, !!firstEligibleStartDate && firstEligibleStartDate > todayUtc];
};

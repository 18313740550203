/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import { booleanAttribute } from './booleanAttribute';

const analyticsEnv = process.env.GATSBY_ANALYTICS_ENV;

const gtmScriptProduction = `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-5M6B638');
`;

const gtmScriptStaging = `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=ccNxp44GWoYU1a_-ui4PAg&gtm_preview=env-6&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-5M6B638');
`;

const gtmNoscriptProductionUrl =
  'https://www.googletagmanager.com/ns.html?id=GTM-5M6B638';
const gtmNoscriptStagingUrl =
  'https://www.googletagmanager.com/ns.html?id=GTM-5M6B638&gtm_auth=ccNxp44GWoYU1a_-ui4PAg&gtm_preview=env-6&gtm_cookies_win=x';

export const gtmScript: JSX.Element = (
  <script async={booleanAttribute} defer={booleanAttribute}>
    {analyticsEnv === 'production' ? gtmScriptProduction : gtmScriptStaging}
  </script>
);

export const gtmNoscript: JSX.Element = (
  <noscript>
    <iframe
      src={
        analyticsEnv === 'production' ? gtmNoscriptProductionUrl : gtmNoscriptStagingUrl
      }
      height="0"
      width="0"
      style={{
        display: 'none',
        visibility: 'hidden',
      }}
    />
  </noscript>
);

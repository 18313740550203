import React from 'react';

const analyticsEnv = process.env.GATSBY_ANALYTICS_ENV;

const dataDomainScriptProduction = '4b9d9df5-0c33-4601-a173-683a04bc52b9';
const dataDomainScriptTest = '4b9d9df5-0c33-4601-a173-683a04bc52b9-test';

const oneTrustScripts: JSX.Element[] = [
  <script
    src="https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js"
    type="text/javascript"
    data-domain-script={
      analyticsEnv === 'production' ? dataDomainScriptProduction : dataDomainScriptTest
    }
    key="oneTrustScripts"
  />,
  <script type="text/javascript" key="oneTrustWrapper">
    {'function OptanonWrapper() {}'}
  </script>,
];

export default oneTrustScripts;

// eslint-disable-next-line import/no-extraneous-dependencies
import { useLocation } from '@reach/router';
import { parseDate } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { getCoverLevelFromQuoteOptions } from 'apiHelpers/quote/bundleCoverMapping';
import { QuoteResponsePetInfo } from 'apiHelpers/quote/quoteResponse';
import { petAgeIsEqualToTwoMonths } from 'businessLogic/aggregators';
import { useEffect, useState, version } from 'react';
import { useSelector } from 'react-redux';
import { formatInPoundsWithSign } from 'helpers/poundsHelpers';
import { RootState } from 'state/createStore';
import { ProductType, QuoteOptions } from 'state/formData/quoteOptions';
import { ReferralCodes, useReferralCodes } from 'state/formData/referralCodes';
import useReferenceData from 'state/referenceData/useReferenceData';
import { ReferenceDataOption } from 'types/referenceData';
import { getFullYears } from './ageHelpers';
import { ProductId } from './businessConstants';
import { countDaysBetweenDates } from './dateHelpers';
import {
  ANNUAL_PAYMENT_PSEUDO_URL,
  getAggregatorFromProductId,
  getCoverTypeLabelFromCoverLevel,
  getPetBreedInReadableForm,
  getReadableExcessAmount,
  getReadablePetBreedType,
  getVetFeeLimit,
  MONTHLY_PAYMENT_PSEUDO_URL,
  PageTrackingEventProps,
  trackEvent,
  TrackingEvent,
  userHasSelectedCover,
} from './eventTracking';
import {
  dogBreedType_CROSS_BREED,
  dogBreedType_MONGREL,
  petType_DOG,
} from './referenceDataConstants';
import { quoteAndBuyRoutes } from './routingHelper';
import { QUOTE_OPTIONS_SESSION_KEY, retrieveData } from './sessionStorageHelpers';
import { CurrentQuote, useCurrentQuote } from './useCurrentQuote';
import { useMetaTitle } from './useMetaTitle';

export const VOLUNTARY_EXCESS = '0%';

export const getGeneralPageTrackingData = (
  quote: CurrentQuote | null,
  catBreedsRefData: ReferenceDataOption[],
  dogBreedsRefData: ReferenceDataOption[],
  productType?: ProductType,
  referralCodes?: ReferralCodes,
  paymentMethod?: 'Annual' | 'Monthly'
): PageTrackingEventProps => {
  if (!quote) {
    return {};
  }

  // The isAnnualPayment method is always initialised as false but we only want to pass
  // the payment method property if a quote has been generated
  const getPaymentMethod = (): 'Annual' | 'Monthly' | undefined => {
    if (!quote.petInfos) {
      return undefined;
    }
    return quote.quoteOptions.isAnnualPayment ? 'Annual' : 'Monthly';
  };

  const productName = productType ?? 'none selected';

  const pageTrackingData: PageTrackingEventProps = {
    aggregatorQuoteId: getAggregatorFromProductId(quote.productId),
    breed: quote?.petInfos
      ?.map((petInfo) =>
        getPetBreedInReadableForm(petInfo, catBreedsRefData, dogBreedsRefData)
      )
      .join(','),
    breedType: quote.petInfos
      ?.map((petInfo) => getReadablePetBreedType(petInfo.petBreedType))
      .join(','),
    daysUntilStartDate: quote.policyInfo
      ? countDaysBetweenDates(new Date(), parseDate(quote.policyInfo.coverStartDate))
      : undefined,
    excess: quote?.petInfos
      ?.map((petInfo) => getReadableExcessAmount(petInfo.voluntaryExcessAmount))
      .join(','),
    journeyType: 'Quote and Buy',
    numberOfPets: quote.petInfos ? `${quote.petInfos.length}` : undefined,
    numberOfPetsInHouseHold: quote.policyInfo?.numberPetInHousehold,
    petType: quote.petInfos
      ?.map((petInfo) => (petInfo.petType === petType_DOG ? 'Dog' : 'Cat'))
      .join(','),
    paymentMethod: paymentMethod || getPaymentMethod(),
    policyStartDate: quote.policyInfo?.coverStartDate,
    quoteId: quote.policyInfo?.quoteNumber || undefined,
    productName,
    vetBillsAmount:
      formatInPoundsWithSign(getVetFeeLimit(quote.quoteOptions)) || undefined,
    channelAffinityCode: referralCodes?.affinity || undefined,
    channelSubAffinityCode: referralCodes?.subAffinity || undefined,
    petAge: quote?.petInfos
      ?.map((petInfo) => `${getFullYears(new Date(petInfo.dob))}`)
      .join(', '),
  };

  return pageTrackingData;
};

export const trackPageView = (
  path: string,
  pageTitle: string,
  quote: CurrentQuote,
  catBreedsRefData: ReferenceDataOption[],
  dogBreedsRefData: ReferenceDataOption[],
  productType?: undefined,
  referralCodes?: ReferralCodes,
  paymentMethod?: 'Annual' | 'Monthly'
): void => {
  const quoteOptions: QuoteOptions = JSON.parse(
    retrieveData(QUOTE_OPTIONS_SESSION_KEY) || '{}'
  );

  const getPath = (): string => {
    if (path === quoteAndBuyRoutes.payment) {
      return quoteOptions.isAnnualPayment
        ? ANNUAL_PAYMENT_PSEUDO_URL
        : MONTHLY_PAYMENT_PSEUDO_URL;
    }
    return path;
  };

  const isCrossbreedDog = (pet: QuoteResponsePetInfo): boolean =>
    pet.petBreedType === dogBreedType_CROSS_BREED ||
    pet.petBreedType === dogBreedType_MONGREL;

  const getAdditionalQuestionsDisplayed = (
    currentQuote: CurrentQuote
  ): string | undefined => {
    if (currentQuote.productId !== ProductId.COMPARE_THE_MARKET) return undefined;

    const questions = [];

    if (currentQuote.petInfos?.some((pet) => petAgeIsEqualToTwoMonths(new Date(pet.dob))))
      questions.push('DOB');

    if (currentQuote.petInfos?.some((pet) => isCrossbreedDog(pet)))
      questions.push('Breed');

    return questions.length ? questions.join(', ') : undefined;
  };

  const event: TrackingEvent = {
    event: 'newPage',
    vpvPath: getPath(),
    pageTitle,
    siteVersion: version,
    additionalQuestionsDisplayed: getAdditionalQuestionsDisplayed(quote),
    quoteId: quote?.policyInfo?.quoteNumber || undefined,
    ...getGeneralPageTrackingData(
      quote,
      catBreedsRefData,
      dogBreedsRefData,
      productType,
      referralCodes,
      paymentMethod
    ),
    // Explicitly flush other variables
    eventCategory: undefined,
    eventAction: undefined,
    eventLabel: undefined,
    isException: undefined,
  };

  if (userHasSelectedCover(quote)) {
    event.productName = getCoverTypeLabelFromCoverLevel(
      getCoverLevelFromQuoteOptions(quote.quoteOptions)
    );
  }

  trackEvent(event);
};

// Hook to track once when the quote loads
export const usePageTracking = (
  metaTitle: string,
  pageLoaded = true,
  quoteToTrack?: CurrentQuote | null
): void => {
  const [tracked, setTracked] = useState(false);
  const [referralCodes] = useReferralCodes();
  const { pathname } = useLocation();
  const getFullMetaTitle = useMetaTitle();
  const error = useSelector((state: RootState) => state.error);
  const currentQuote = useCurrentQuote();

  const EMPTY: never[] = [];

  const catBreedsRefData = useReferenceData('catBreeds')?.catBreeds ?? EMPTY;
  const dogBreedsRefData = useReferenceData('dogBreeds')?.dogBreeds ?? EMPTY;

  useEffect(() => {
    if (!tracked && !error.errorType && metaTitle && pageLoaded) {
      trackPageView(
        pathname,
        getFullMetaTitle(metaTitle),
        quoteToTrack || currentQuote,
        catBreedsRefData,
        dogBreedsRefData,
        undefined,
        referralCodes
      );
      setTracked(true);
    }
  }, [
    error.errorType,
    metaTitle,
    pathname,
    getFullMetaTitle,
    tracked,
    currentQuote,
    pageLoaded,
    quoteToTrack,
    catBreedsRefData,
    dogBreedsRefData,
    referralCodes,
  ]);
};

import { AddOnType } from 'businessLogic/addOns';

export enum CoverType {
  Accidents_Only = 'Accidents_Only',
  Accident_And_Illness = 'Accident_And_Illness',
}

export enum AccidentAndIllnessCoverDuration {
  Short_Term = 'basic',
  Until_Limit = 'classic',
  Ongoing = 'premier',
}

export enum BasicCoverFeeLimit {
  Limit_1500 = 1500,
  Limit_3000 = 3000,
}

export enum ClassicCoverFeeLimit {
  Limit_4000 = 4000,
  Limit_8000 = 8000,
}

export enum PremierCoverFeeLimit {
  Limit_1000 = 1000,
  Limit_2000 = 2000,
  Limit_4000 = 4000,
  Limit_6000 = 6000,
}

export enum ProductType {
  None = 'none',
  Basic_Cover = 'basic',
  Classic_Cover = 'classic',
  Premier_Cover = 'premier',
}
export type AddOnsSelected = {
  [T in AddOnType]: boolean | null;
};

export type QuoteOptions = {
  coverType: CoverType | undefined;
  accidentAndIllnessCoverDuration: AccidentAndIllnessCoverDuration | undefined;
  basicCoverFeeLimit: BasicCoverFeeLimit | undefined;
  classicCoverFeeLimit: ClassicCoverFeeLimit | undefined;
  premierCoverFeeLimit: PremierCoverFeeLimit | undefined;
  isAnnualPayment: boolean;
  additionalQuestionBasic: boolean | undefined;
  additionalQuestionCheapestPremier: boolean | undefined;
  addOnsSelected: AddOnsSelected;
};

export const initialAddOnsSelected = {
  lostAndFound: null,
  petsAbroad: null,
  farewell: null,
};

export const initialQuoteOptions: QuoteOptions = {
  coverType: CoverType.Accident_And_Illness,
  accidentAndIllnessCoverDuration: undefined,
  basicCoverFeeLimit: undefined,
  classicCoverFeeLimit: undefined,
  premierCoverFeeLimit: undefined,
  isAnnualPayment: false,
  additionalQuestionBasic: undefined,
  additionalQuestionCheapestPremier: undefined,
  addOnsSelected: initialAddOnsSelected,
};

export const UPDATE_QUOTE_OPTIONS = 'UPDATE_QUOTE_OPTIONS';

export type UpdateQuoteOptionsAction = {
  type: typeof UPDATE_QUOTE_OPTIONS;
  update: QuoteOptions;
};

import { TableCell } from '@rsa-digital/evo-shared-components/components/Table';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import styled, { css } from 'styled-components';

export const TableHeaderRowCellWithOptionalHeaderStyling = styled(TableCell)`
  ${({ isHeader }) =>
    isHeader &&
    css`
      &,
      // If this is a heading cell in the body, then we are using rich text and so will also need to
      // override the paragraph styling for this to have any affect.
      & p {
        ${fonts.paragraph}
      }
      & div {
        color: ${colors.neutral01};
      }
      background-color: ${colors.neutral06};
    `}
  div {
    font-size: 1rem;
  }
  text-align: ${({ align }) => align};
`;

export const TableBodyRowCellWithOptionalHeaderStyling = styled(TableCell)<{
  isBoldText?: boolean;
}>`
  ${({ isHeader }) =>
    isHeader &&
    css`
      &,
      // If this is a heading cell in the body, then we are using rich text and so will also need to
      // override the paragraph styling for this to have any affect.
      & p {
        ${fonts.paragraph}
      }
      & div {
        color: ${colors.neutral08};
      }
      background-color: ${colors.core01};
    `}
  div {
    font-size: 1rem;
  }
  text-align: ${({ align }) => align};
  div {
    font-weight: ${({ isBoldText }) => (isBoldText ? 'bold' : 'auto')};
  }
`;

import {
  getBundleFromQuote,
  getCoverLevelFromQuoteOptions,
  getSelectedBundleCovers,
} from 'apiHelpers/quote/bundleCoverMapping';
import { QuoteCustomerInfo, QuotePolicyInfo } from 'apiHelpers/quote/quoteRequest';
import { Bundle, Quote, QuoteResponsePetInfo } from 'apiHelpers/quote/quoteResponse';
import { AddOnPrices, AddOnType, getAddOnCoverCodeFromType } from 'businessLogic/addOns';
import { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from 'state/createStore';
import { QuoteOptions, UPDATE_QUOTE_OPTIONS } from 'state/formData/quoteOptions';
import { PriceSummary } from 'state/quote/types';
import {
  getAddOnPricesForSelectedBundle,
  getAddOnsTotalPricePerPet,
} from './addOnPriceHelpers';
import { AddOn, ProductId } from './businessConstants';
import { getTotalPrice } from './priceHelpers';

export type CurrentQuote = {
  petInfos: QuoteResponsePetInfo[] | null;
  customerInfo: QuoteCustomerInfo | null;
  policyInfo: QuotePolicyInfo | null;
  quoteOptions: QuoteOptions;
  price: PriceSummary | null;
  productId: ProductId;
  selectedBundleCovers: string[];
};

const parsePricePerPet = (
  basePricePerPet: number[],
  addOnPricePerPet: number[]
): { total: number; perPet: number[] } => ({
  total: getTotalPrice(...basePricePerPet, ...addOnPricePerPet),
  perPet: basePricePerPet.map((basePrice, i) =>
    parseFloat((basePrice + (addOnPricePerPet[i] ?? 0)).toFixed(2))
  ),
});

export const getPricesFromBundle = (
  bundle: Bundle,
  selectedAddOnCoverCodes: AddOn[]
): PriceSummary => {
  const {
    annualPrice: { petPrices: annualBasePricePerPet },
    installments,
  } = bundle;
  const monthlyBasePricePerPet = installments[0].petPrices;

  const addOnPricesPerPet: AddOnPrices[] = selectedAddOnCoverCodes.map((coverCode) =>
    getAddOnPricesForSelectedBundle(bundle, coverCode)
  );

  const {
    annualPricePerPet: annualAddOnPricePerPet,
    monthlyPricePerPet: monthlyAddOnPricePerPet,
  } = getAddOnsTotalPricePerPet(addOnPricesPerPet);

  return {
    annualPrice: parsePricePerPet(annualBasePricePerPet, annualAddOnPricePerPet),
    monthlyPrice: parsePricePerPet(monthlyBasePricePerPet, monthlyAddOnPricePerPet),
  };
};

const getPricesFromQuote = (
  quoteOptions: QuoteOptions,
  quote: Quote
): PriceSummary | null => {
  const coverLevel = getCoverLevelFromQuoteOptions(quoteOptions);
  const selectedAddOnCoverCodes: AddOn[] = Object.entries(quoteOptions.addOnsSelected)
    .filter(([, isSelected]) => !!isSelected)
    .map(([type]) => getAddOnCoverCodeFromType(type as AddOnType)) as AddOn[];

  const bundle = getBundleFromQuote(coverLevel, quote);
  return bundle ? getPricesFromBundle(bundle, selectedAddOnCoverCodes) : null;
};

const currentQuoteSelector = (state: RootState): CurrentQuote => {
  const { quote, quoteSummaryOptions } = state;

  return {
    petInfos: quote?.petInfos ?? null,
    customerInfo: quote?.customerInfo ?? null,
    policyInfo: quote?.policyInfo ?? null,
    quoteOptions: quoteSummaryOptions,
    price: quote ? getPricesFromQuote(quoteSummaryOptions, quote) : null,
    productId: quote?.productId ?? ProductId.DIRECT,
    selectedBundleCovers: quote ? getSelectedBundleCovers(quote) : [],
  };
};

/**
 * Provides the quote data and active quote options in an easily readable state
 */
export const useCurrentQuote = (): CurrentQuote =>
  useSelector(currentQuoteSelector, shallowEqual);

export const useUpdateQuoteOptions = (): ((update: Partial<QuoteOptions>) => void) => {
  const dispatch = useDispatch();

  return useCallback(
    (update: Partial<QuoteOptions>): void => {
      dispatch({ type: UPDATE_QUOTE_OPTIONS, update });
    },
    [dispatch]
  );
};

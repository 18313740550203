import { CurrentQuote } from 'helpers/useCurrentQuote';
import { RESET_STATE, ResetStateAction } from 'state/actions';
import {
  UPDATE_CONFIRMATION_QUOTE,
  UpdateConfirmationQuoteAction,
} from './confirmationQuote';

export const confirmationQuoteReducer = (
  confirmationQuote: CurrentQuote | null = null,
  action: UpdateConfirmationQuoteAction | ResetStateAction
): CurrentQuote | null => {
  switch (action.type) {
    case UPDATE_CONFIRMATION_QUOTE:
      return action.update && { ...action.update };
    case RESET_STATE:
      return null;
    default:
      return confirmationQuote;
  }
};

import { graphql, useStaticQuery } from 'gatsby';

type KickoutMessageText = {
  csPetGlobalConfig: {
    quote_kick_out_message: string;
  };
};

const kickoutMessageTextQuery = graphql`
  query {
    csPetGlobalConfig {
      quote_kick_out_message
    }
  }
`;

const useKickoutMessageText = (): string => {
  return useStaticQuery<KickoutMessageText>(kickoutMessageTextQuery).csPetGlobalConfig
    .quote_kick_out_message;
};
export default useKickoutMessageText;

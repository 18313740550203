import { required } from '@rsa-digital/evo-shared-components/helpers/forms/rules';
import { ValidationRules } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { QuoteResponsePetInfo } from 'apiHelpers/quote/quoteResponse';
import { isPetAlreadyAgeNineForCover } from 'businessLogic/petAge';
import { graphql, useStaticQuery } from 'gatsby';
import { Dispatch, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { VoluntaryExcessAmount } from 'helpers/businessConstants';
import { RootState } from 'state/createStore';
import { UPDATE_QUOTE, UpdateQuoteAction } from 'state/quote/quote';
import { updateItem } from '../../../../helpers/arrayHelper';
import { useCurrentQuote } from '../../../../helpers/useCurrentQuote';

export type TwentyPercentExcessAgreement = {
  hasAgreed: boolean | undefined;
};

type CsTwentyPercentExcessErrorText = {
  csPetQuoteSummaryChooseExcess: {
    twenty_percent_excess: {
      twenty_percent_excess_agreement_error_text: string;
      twenty_percent_excess_missing_agreement_error_text: string;
    };
  };
};

export const query = graphql`
  query {
    csPetQuoteSummaryChooseExcess {
      twenty_percent_excess {
        twenty_percent_excess_agreement_error_text
        twenty_percent_excess_missing_agreement_error_text
      }
    }
  }
`;

const useTwentyPercentExcessAgreements = (): {
  twentyPercentExcessAgreements: TwentyPercentExcessAgreement[];
  twentyPercentExcessAgreementRules: ValidationRules<{
    twentyPercentExcessAgreements: TwentyPercentExcessAgreement[];
  }>;
  updateTwentyPercentExcessAgreement: (
    update: boolean | undefined,
    index: number
  ) => void;
  resetAllTwentyPercentExcessAgreements: () => void;
} => {
  const agreementErrorTexts = useStaticQuery<CsTwentyPercentExcessErrorText>(query)
    .csPetQuoteSummaryChooseExcess.twenty_percent_excess;

  const notAgreedErrorText =
    agreementErrorTexts.twenty_percent_excess_agreement_error_text;
  const notAnsweredAgreementErrorText =
    agreementErrorTexts.twenty_percent_excess_missing_agreement_error_text;

  const quote = useCurrentQuote();
  const storedQuote = useSelector((state: RootState) => state.quote);
  const dispatchQuote = useDispatch<Dispatch<UpdateQuoteAction>>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const petInfos = quote.petInfos ?? [];

  const initialAgreements = petInfos.map((pet) => ({
    hasAgreed: pet.twentyPercentExcessConfirmation?.hasAgreed,
  }));

  const [twentyPercentExcessAgreements, setTwentyPercentExcessAgreements] = useState<
    TwentyPercentExcessAgreement[]
  >(initialAgreements);

  useEffect(() => {
    // this is required because the tests don't pick up the initial state from the useState, and thus would error when displaying the excess section
    if (twentyPercentExcessAgreements.length === 0 && initialAgreements.length !== 0) {
      setTwentyPercentExcessAgreements(initialAgreements);
    }
  }, [
    twentyPercentExcessAgreements,
    setTwentyPercentExcessAgreements,
    petInfos,
    initialAgreements,
  ]);

  const getTwentyPercentExcessRules = (): ValidationRules<{
    twentyPercentExcessAgreements: TwentyPercentExcessAgreement[];
  }> => {
    const rules: ValidationRules<TwentyPercentExcessAgreement> = {
      hasAgreed: [
        required(notAnsweredAgreementErrorText),
        {
          validityCondition: (value) => value === true,
          errorMessage: notAgreedErrorText,
        },
      ],
    };

    const checkValidationIsNotRequired = (
      petInfo: QuoteResponsePetInfo | undefined
    ): boolean =>
      !petInfo ||
      (quote.policyInfo &&
        isPetAlreadyAgeNineForCover(petInfo.dob, quote.policyInfo.coverStartDate)) ||
      petInfo.voluntaryExcessAmount !== VoluntaryExcessAmount.Excess_20_Percent;

    return {
      twentyPercentExcessAgreements: {
        hasAgreed: rules.hasAgreed?.map(({ validityCondition, ...rest }) => ({
          ...rest,
          validityCondition: (v, data, i) =>
            checkValidationIsNotRequired(petInfos[i]) ||
            validityCondition(v, data.twentyPercentExcessAgreements[i]),
        })),
      },
    };
  };

  const twentyPercentExcessAgreementRules = getTwentyPercentExcessRules();

  const updateTwentyPercentExcessAgreement = (
    update: boolean | undefined,
    index: number
  ): void => {
    setTwentyPercentExcessAgreements((prevState) => {
      if (!prevState) {
        return prevState;
      }
      return updateItem(prevState, index, { hasAgreed: update });
    });
    if (storedQuote) {
      dispatchQuote({
        type: UPDATE_QUOTE,
        quote: {
          ...storedQuote,
          petInfos: storedQuote.petInfos.map((pet, i) => {
            if (index === i) {
              return {
                ...pet,
                twentyPercentExcessConfirmation: {
                  hasAgreed: update,
                },
              };
            }
            return pet;
          }),
        },
      });
    }
  };

  // Sets all twentyPercentExcessAgreements to undefined in Redux > quote > petInfos
  const resetAllTwentyPercentExcessAgreements = (): void => {
    setTwentyPercentExcessAgreements((prevState) => {
      if (!prevState) {
        return prevState;
      }
      return Array(petInfos.length).fill({ hasAgreed: undefined });
    });
    if (storedQuote) {
      dispatchQuote({
        type: UPDATE_QUOTE,
        quote: {
          ...storedQuote,
          petInfos: storedQuote.petInfos.map((pet) => {
            return {
              ...pet,
              twentyPercentExcessConfirmation: {
                hasAgreed: undefined,
              },
            };
          }),
        },
      });
    }
  };

  return {
    twentyPercentExcessAgreements,
    updateTwentyPercentExcessAgreement,
    twentyPercentExcessAgreementRules,
    resetAllTwentyPercentExcessAgreements,
  };
};

export default useTwentyPercentExcessAgreements;

import { NonTextLink } from '@rsa-digital/evo-shared-components/commonTypes/links';
import { ComponentProps } from '@rsa-digital/evo-shared-components/helpers/componentProps';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import ProgressBar, { QuoteAndBuyStep } from 'components/ProgressBar';
import { processImageAsset } from 'helpers/csTypeProcessors';
import { PageTitle, trackIconButtonClick } from 'helpers/eventTracking';
import { CsAsset } from 'types/contentStack';
import { OptionalShadowHeader } from './styles';

type csQuoteAndBuyHeader = {
  csQuoteAndBuyHeader: {
    logo: {
      image: CsAsset;
      url: string;
      link_screen_reader_text: string;
    };
  };
};

const query = graphql`
  query {
    csQuoteAndBuyHeader {
      logo {
        image {
          ...CsAsset
        }
        url
        link_screen_reader_text
      }
    }
  }
`;

const Header: React.FC<
  { pageTitle: PageTitle; currentStep?: QuoteAndBuyStep } & ComponentProps
> = ({ pageTitle, currentStep }) => {
  const { image, url, link_screen_reader_text } = useStaticQuery<csQuoteAndBuyHeader>(
    query
  ).csQuoteAndBuyHeader.logo;

  const urlWithTrailingSlash = url.endsWith('/') ? url : `${url}/`;
  const petHomeLink = `${urlWithTrailingSlash}pet-insurance/`;
  const logoImage = processImageAsset(image);
  const logoLink: NonTextLink = {
    url: petHomeLink,
    screenReaderText: link_screen_reader_text,
    onClick: () => trackIconButtonClick(pageTitle, 'More Than Logo'),
  };
  const logoLinkSelectPagesOnly =
    pageTitle === PageTitle.AboutYourPet || pageTitle === PageTitle.Confirmation
      ? logoLink
      : undefined;

  return (
    <>
      {logoImage && (
        <OptionalShadowHeader
          logoLink={{ link: logoLinkSelectPagesOnly, image: logoImage }}
          displayShadow={!currentStep}
        />
      )}
      {currentStep && <ProgressBar currentStep={currentStep} pageTitle={pageTitle} />}
    </>
  );
};

export default Header;

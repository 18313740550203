import { AxiosError } from 'axios';

const QUOTE_NOT_FOUND_ERROR_CODE = 'EVO_BUS_P175';
const PLCAL_RETRIEVE_QUOTE_FAILED = 'EVO_TEC_P143';
const VAGUE_ADDRESS = 'EVO_BUS_P115';
const UNKNOWN_ADDRESS = 'EVO_BUS_P116';
const MISSING_ADDRESS = 'EVO_BUS_P117';
const MISSING_QUOTE = 'EVO_BUS_P130';
const PAST_DATE_QUOTE = 'EVO_BUS_P136';
const INVALID_QUOTE = 'EVO_BUS_P280';

export const isQuoteNotFoundError = (error: AxiosError): boolean => {
  return (
    error.response?.status === 404 &&
    (error.response?.data?.Code === PLCAL_RETRIEVE_QUOTE_FAILED ||
      error.response?.data?.Code === VAGUE_ADDRESS ||
      error.response?.data?.Code === UNKNOWN_ADDRESS ||
      error.response?.data?.Code === MISSING_ADDRESS ||
      error.response?.data?.Code === MISSING_QUOTE ||
      error.response?.data?.Code === PAST_DATE_QUOTE ||
      error.response?.data?.Code === INVALID_QUOTE ||
      error.response?.data?.Code === QUOTE_NOT_FOUND_ERROR_CODE)
  );
};

import { parseDate } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { Quote, QuoteResponsePetInfo } from 'apiHelpers/quote/quoteResponse';
import { getFullMonths, getFullYears } from 'helpers/ageHelpers';
import { ProductId } from 'helpers/businessConstants';
import { CurrentQuote } from 'helpers/useCurrentQuote';

export const petAgeIsEqualToTwoMonths = (dateOfBirth: Date | undefined): boolean => {
  return dateOfBirth
    ? getFullYears(new Date(dateOfBirth)) === 0 &&
        getFullMonths(new Date(dateOfBirth)) === 2
    : false;
};

const petRequiresAdditionalQuestions = (petOnQuote: QuoteResponsePetInfo): boolean =>
  petAgeIsEqualToTwoMonths(parseDate(petOnQuote.dob));

export const shouldAggsQuoteShowAdditionalQuestions = (
  quote: Quote | CurrentQuote
): boolean =>
  quote.productId === ProductId.COMPARE_THE_MARKET &&
  (quote.petInfos?.some((petInfo) => petRequiresAdditionalQuestions(petInfo)) ?? false);

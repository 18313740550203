import {
  ProductCard,
  ProductCardGrid,
} from '@rsa-digital/evo-shared-components/components/ProductCard';
import { graphql } from 'gatsby';
import React from 'react';
import {
  processImageAsset,
  processMandatoryCta,
  processTextLink,
  unwrapSingleton,
} from 'helpers/csTypeProcessors';
import { PageTitle } from 'helpers/eventTracking';
import { handleLinkClick } from 'helpers/navigation';
import { CsAsset, CsCta, CsIcon, CsLink } from 'types/contentStack';

type ProductCardsBlockProps = {
  product_cards: {
    product_cards: {
      icon: [CsIcon] | [];
      image: CsAsset | null;
      heading: string;
      heading_link_url: string | null;
      body: string | null;
      primary_cta: [CsCta];
      text_link: CsLink | null;
    }[];
    compact?: boolean;
    pageTitle: PageTitle;
  };
  compact?: boolean | undefined;
};

export const query = graphql`
  fragment ProductCardsBlockError on cs__error_page_blocks {
    product_cards {
      product_cards {
        icon {
          icon_code
        }
        image {
          ...CsAsset
        }
        heading
        heading_link_url
        body
        primary_cta {
          display_text
          screen_reader_text
          url
        }
        text_link {
          href
          title
        }
      }
    }
  }
`;

const ProductCardsBlock: React.FC<ProductCardsBlockProps> = ({
  product_cards,
  compact,
}) => (
  <ProductCardGrid data-cy="ProductCards" compact={compact}>
    {product_cards.product_cards.map(
      (
        { heading, heading_link_url, icon, image, body, primary_cta, text_link },
        index
      ) => {
        const headingLink = heading_link_url
          ? {
              url: heading_link_url,
              onClick: handleLinkClick(heading_link_url),
            }
          : undefined;

        const processedImage = processImageAsset(image);
        // If both an image and icon are passed in from ContentStack, we prioritise the image.
        const processedIcon = processedImage
          ? undefined
          : unwrapSingleton(icon)?.icon_code;

        return (
          <ProductCard
            data-cy={`ProductCard${index}`}
            key={heading}
            icon={processedIcon}
            image={processedImage}
            heading={heading}
            headingLink={headingLink}
            body={body ?? undefined}
            cta={processMandatoryCta(primary_cta, product_cards.pageTitle)}
            link={processTextLink(text_link)}
          />
        );
      }
    )}
  </ProductCardGrid>
);

export default React.memo(ProductCardsBlock);

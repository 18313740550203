import { PrimaryCta } from '@rsa-digital/evo-shared-components/components/Cta';
import { StyledSecondaryCta } from '@rsa-digital/evo-shared-components/components/CtaBanner/styles';
import { graphql } from 'gatsby';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { processOptionalCta } from 'helpers/csTypeProcessors';
import { PageTitle } from 'helpers/eventTracking';
import { ErrorAction, resetErrorStateAction } from 'state/error/actions';
import { CsCta } from 'types/contentStack';
import { StyledCtaWrapper } from './styles';

type CtaBlockProps = {
  cta: {
    primary_cta: [CsCta];
    secondary_cta: [CsCta] | null;
    pageTitle: PageTitle;
  };
};

export const query = graphql`
  fragment CtaBlockError on cs__error_page_blocks {
    cta {
      primary_cta {
        display_text
        screen_reader_text
        url
      }
      secondary_cta {
        display_text
        screen_reader_text
        url
      }
    }
  }
`;

const CtaBlock: React.FC<CtaBlockProps> = ({ cta }) => {
  const dispatch = useDispatch<Dispatch<ErrorAction>>();
  const primaryCta = processOptionalCta(cta.primary_cta, cta.pageTitle, () =>
    dispatch(resetErrorStateAction)
  );
  const secondaryCta = processOptionalCta(cta.secondary_cta, cta.pageTitle, () =>
    dispatch(resetErrorStateAction)
  );
  return (
    <StyledCtaWrapper data-cy="CTA Block">
      {primaryCta && <PrimaryCta cta={primaryCta} />}
      {secondaryCta && <StyledSecondaryCta background="light" cta={secondaryCta} />}
    </StyledCtaWrapper>
  );
};

export default React.memo(CtaBlock);

import Accordion, {
  AccordionEntry,
} from '@rsa-digital/evo-shared-components/components/Accordion';
import { graphql } from 'gatsby';
import React from 'react';
import { mapAccordionBlocks } from 'helpers/blockMapper';
import { trackAccordionClick } from 'helpers/eventTracking';
import { Accordion as AccordionType } from '../types';

type AccordionBlockProps = {
  accordion: AccordionType;
};

export const query = graphql`
  fragment AccordionBlockError on cs__error_page_blocks {
    accordion {
      ...AccordionEntriesErrorAccordion
    }
  }
`;

const AccordionBlock: React.FC<AccordionBlockProps> = ({ accordion }) => {
  const accordionEntries = accordion.accordion_entries.map(
    (entry): AccordionEntry => ({
      title: entry.accordion_entry.title,
      content: mapAccordionBlocks(entry.accordion_entry.content),
      onExpand: () => trackAccordionClick('expand', entry.accordion_entry.title),
      onCollapse: () => trackAccordionClick('collapse', entry.accordion_entry.title),
    })
  );

  return (
    <Accordion
      id={accordion.is_contact_us_section ? 'contact-us' : undefined}
      data-cy="Accordion"
      accordionEntries={accordionEntries}
      initiallyDisplayEntries={false}
    />
  );
};

export default React.memo(AccordionBlock);

import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CurrentQuote } from 'helpers/useCurrentQuote';
import { RootState } from 'state/createStore';

export const UPDATE_CONFIRMATION_QUOTE = 'UPDATE_CONFIRMATION_QUOTE';

export type UpdateConfirmationQuoteAction = {
  type: typeof UPDATE_CONFIRMATION_QUOTE;
  update: CurrentQuote | null;
};

export const useConfirmationQuote = (): [
  CurrentQuote | null,
  (update: CurrentQuote | null) => void
] => {
  const confirmationQuote = useSelector((state: RootState) => state.confirmationQuote);
  const dispatch = useDispatch();

  const updateConfirmationQuote = useCallback(
    (update: CurrentQuote | null): void => {
      dispatch({ type: UPDATE_CONFIRMATION_QUOTE, update });
    },
    [dispatch]
  );

  return [confirmationQuote, updateConfirmationQuote];
};

import React from 'react';

/**
 * AppDynamics integration works in three parts:
 * 1 - We set some config on the window that can be read by the scripts
 * 2 - Load adrum.js to bootstrap the agent
 * 3 - The agent will load adrum-ext.xxx.js asynchronously to begin instrumentation
 *
 * https://docs.appdynamics.com/display/PRO21/Inject+the+JavaScript+Agent
 */

const configScript = `
window["adrum-start-time"] = new Date().getTime();
(function(config){
  config.appKey = "${process.env.GATSBY_APPDYNAMICS_KEY}";
  config.adrumExtUrlHttp = "http://cdn.appdynamics.com";
  config.adrumExtUrlHttps = "https://cdn.appdynamics.com";
  config.beaconUrlHttp = "http://pdx-col.eum-appdynamics.com";
  config.beaconUrlHttps = "https://pdx-col.eum-appdynamics.com";
  config.useHTTPSAlways = true;
  
  config.resTiming = {"bufSize":200,"clearResTimingOnBeaconSend":true};
  config.maxUrlLength = 512;
  
})(window["adrum-config"] || (window["adrum-config"] = {}));
`;

// Adrum has a bad interaction with Ensighten Privacy which causes the entire page to
// hang when Ensighten blocks the adrum script.
//
// As a workaround, we inspect the Ensighten Privacy cookies ourselves to pause adrum
// reporting when measurement cookies are disabled.
//
// https://docs.appdynamics.com/21.6/en/end-user-monitoring/browser-monitoring/browser-real-user-monitoring/configure-the-javascript-agent/disable-the-javascript-agent-at-runtime
const enableScript = `
  var getMeasurementCookie = function () {
    var cookie = document.cookie.match(/(^|; )TESCOBANK_ENSIGHTEN_PRIVACY_Measurement=([^;]);/);
    return cookie && cookie[2] === '1';
  };

  var measurementEnabled = getMeasurementCookie();
  if (!measurementEnabled) {
    // Measurement cookies are not explicitly enabled - pause adrum reporting
    ADRUM.pauseReporting(true);
    if (measurementEnabled === null) {
      // Measurement cookies are not yet set - poll until they are
      var id = setInterval(function () {
        var enabled = getMeasurementCookie();
        if (enabled !== null) {
          ADRUM.pauseReporting(!enabled);
          clearInterval(id);
        }
      }, 100);
    }
  }
`;

const appDynamicsScripts: JSX.Element[] = [
  <script key="config">{configScript}</script>,
  <script
    key="adrum"
    data-rh="true"
    src="//cdn.appdynamics.com/adrum/adrum-23.3.0.4265.js"
    type="text/javascript"
    className="optanon-category-C0002"
  />,
  <script key="enable">{enableScript}</script>,
];

export default process.env.GATSBY_APPDYNAMICS_KEY ? appDynamicsScripts : [];

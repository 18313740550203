import { AddOnChooserContainerInputOptions } from '@rsa-digital/evo-shared-components/components/AddOnChooser/types';
import { BundleCover } from 'apiHelpers/quote/quoteResponse';
import React, { useState } from 'react';
import CustomModal from 'components/CustomModal';
import useRichTextWithModal from 'components/RichTextWithModal/useRichTextwithModal';
import { unwrapSingleton } from 'helpers/csTypeProcessors';
import {
  PageTitle,
  trackRadioButtonClick,
  trackRichTextLinkClick,
  trackTextButtonClick,
} from 'helpers/eventTracking';
import addOnPlaceholders from 'helpers/placeholders/addOnPlaceholders';
import {
  replacePlaceholdersPlainText,
  replacePlaceholdersRichText,
} from 'helpers/placeholders/replaceCsPlaceholders';
import { getTotalPrice } from 'helpers/priceHelpers';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { CsAddOn } from 'types/contentStack';
import { useAddOnChooserContent } from './content';
import { StyledAddOnChooser } from './styles';

export type AddOnChooserProps = {
  id: string;
  addOnContent: CsAddOn;
  addOnCover: BundleCover;
  isSelected: boolean | null;
  onUpdateSelection: (includeCover: boolean) => Promise<void>;
  validateSelection?: boolean;
  pageTitle: PageTitle;
};

const AddOnChooser: React.FC<AddOnChooserProps> = ({
  id,
  addOnCover,
  isSelected,
  validateSelection,
  addOnContent,
  onUpdateSelection,
  pageTitle,
}) => {
  const { modalId, setModalId, handleModalButtonClick } = useRichTextWithModal();
  const currentQuote = useCurrentQuote();

  const panelStateOptions = useAddOnChooserContent();
  const { isAnnualPayment } = currentQuote.quoteOptions;

  const price = isAnnualPayment
    ? getTotalPrice(...addOnCover.annuallyBundleCoverPrice.petPrices)
    : getTotalPrice(...addOnCover.monthlyBundleCoverPrice.petPrices);

  const replaceAddOnPlaceholdersRichText = replacePlaceholdersRichText(
    addOnPlaceholders,
    { quote: currentQuote, price }
  );
  const replaceAddOnPlaceholdersPlainText = replacePlaceholdersPlainText(
    addOnPlaceholders,
    { quote: currentQuote, price }
  );

  const subheading = isAnnualPayment
    ? replaceAddOnPlaceholdersPlainText(panelStateOptions.annualSubheading)
    : replaceAddOnPlaceholdersPlainText(panelStateOptions.monthlySubheading);

  const summary = replaceAddOnPlaceholdersRichText(addOnContent.summary);

  const [containerInputValue, setContainerInputValue] = useState<
    AddOnChooserContainerInputOptions | undefined
  >(undefined);

  return (
    <>
      <StyledAddOnChooser
        id={id}
        containerInputValue={containerInputValue}
        setContainerInputValue={setContainerInputValue}
        isSelected={isSelected}
        validateSelection={validateSelection}
        chooserContainerInfo={{
          heading: addOnContent.heading,
          subheading,
          iconOrImage: unwrapSingleton(addOnContent.icon)?.icon_code,
          summary,
        }}
        onSelectCover={() => {
          trackTextButtonClick(pageTitle, `acceptAddOn - ${addOnCover.cover.name}`);
          return onUpdateSelection(true);
        }}
        onDeclineCover={() => {
          trackTextButtonClick(pageTitle, `declineAddOn - ${addOnCover.cover.name}`);
          return onUpdateSelection(false);
        }}
        onExpand={(event) =>
          trackRadioButtonClick(event.target.value, addOnCover.cover.name)
        }
        {...panelStateOptions}
        onClick={handleModalButtonClick}
        onLinkClick={(label) =>
          trackTextButtonClick(pageTitle, `${label} - ${addOnCover.cover.name}`)
        }
      />
      <CustomModal
        trackRichTextLinkClick={trackRichTextLinkClick(pageTitle, 'add on chooser')}
        modalId={modalId}
        onClose={() => setModalId(null)}
        pageTitle={pageTitle}
      />
    </>
  );
};

export default AddOnChooser;

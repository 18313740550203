import { mapApiOption } from 'apiHelpers/referenceData/mapApiOption';
import { ApiReferenceDataOption, ReferenceDataOption } from 'types/referenceData';

export type GendersReferenceDataResponse = {
  categoryName: string;
  items: ApiReferenceDataOption[];
};

export type GendersReferenceData = {
  genders: ReferenceDataOption[];
};

export const mapGendersRefData = (
  data: GendersReferenceDataResponse
): GendersReferenceData => ({
  // Reverse is used here so that the 'Male' option comes first to match the designs
  genders: data.items.map(mapApiOption).reverse(),
});
